import React from "react";
import "./css/Loading.css";

export default function Loading() {
  return (
    <div className="snippet">
      <div className="stage">
        <div className="dot-bricks"></div>
      </div>
    </div>
  );
}
