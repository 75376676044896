//AIL
import imgAil_1 from "./image/Ail/Ail_copy.webp";
import imgAil_2 from "./image/Ail/Ail_title_copy.webp";
import imgAil_3 from "./image/Ail/Ail_morceau_.webp";
import imgAil_4 from "./image/Ail/Ail_en_poudre.webp";
import imgAil_5 from "./image/Ail/Ail_morceau_2.webp";
import imgAil_6 from "./image/Ail/Ail_en_poudre2.webp";
//ANIS
import imgAnis_1 from "./image/Anis/Anis_copy.webp";
import imgAnis_2 from "./image/Anis/Anis_Title_copy.webp";
import imgAnis_5 from "./image/Anis/Anis_étoilé.webp";

//BOIS
import imgBoisAnis_1 from "./image/Bois d_anis (PHOTO)/Bois_d_anis_copy.webp";
import imgBoisAnis_2 from "./image/Bois d_anis (PHOTO)/Bois_d_anis_title_copy.webp";

//RIMAMBELLE
import imgR_1 from "./image/Rimambelle/ribambelle_copy.webp";
import imgR_2 from "./image/Rimambelle/ribambelle_title_copy.webp";
//BAIE ROSE
import imgBaie_1 from "./image/Baie Rose/Baie_Rose_copy.webp";
import imgBaie_2 from "./image/Baie Rose/Baie_Rose_Title_copy.webp";
import imgBaie_3 from "./image/Baie Rose/Baie_Rose1.webp";
import imgBaie_5 from "./image/Baie Rose/Baie_Rose_4.webp";
import imgBaie_7 from "./image/Baie Rose/Baie_Rose_3.webp";
import imgBaie_8 from "./image/Baie Rose/Baie_rose2.webp";
//BAOBAB
import imgBaobab_1 from "./image/Baobab/Baobab_copy.webp";
import imgBaobab_2 from "./image/Baobab/Baobab_title_copy.webp";
import imgBaobab_6 from "./image/Baobab/Baobab_poudre.webp";
//CACAO
import imgCacao_1 from "./image/Cacao/Cacao_copy.webp";
import imgCacao_2 from "./image/Cacao/Cacao_Title_copy.webp";
import imgCacao_7 from "./image/Cacao/Feve_de_cacao.webp";
//CURCUMA
import imgCurcuma_1 from "./image/CURCUMA/imgCurcuma_1.webp";
import imgCurcuma_2 from "./image/CURCUMA/imgCurcuma_1.webp";
import imgCurcuma_6 from "./image/CURCUMA/Cucrcuma_Poudre_2.webp";
import imgCurcuma_4 from "./image/CURCUMA/Curcuma_Poudre.webp";
//CANELLA
import imgCannela_1 from "./image/Cannela/imgCinamon_1.webp";
import imgCannela_2 from "./image/Cannela/imgCinamon_2.webp";
//CANELLE
import imgCanelle_1 from "./image/Cannelle/Cannelle_copy.webp";
import imgCanelle_2 from "./image/Cannelle/Cannelle_Title_copy.webp";
import imgCanelle_8 from "./image/Cannelle/Cannelle_en_poudre_2.webp";
import imgCanelle_4 from "./image/Cannelle/Cannelle_en_Poudre.webp";
import imgCanelle_7 from "./image/Cannelle/Cannelle_Poudre.webp";
import imgCanelle_5 from "./image/Cannelle/Cannelle_Rouleau.webp";
import imgCanelle_6 from "./image/Cannelle/Cannelle_en_poudre_3.webp";
//CITRONELLE
import imgCitronelle_1 from "./image/Citronnelle/Citronnelle_copy.webp";
import imgCitronelle_2 from "./image/Citronnelle/Citronnelle_title_copy.webp";
import imgCitronelle_5 from "./image/Citronnelle/Citronnelle.webp";
//GIROFLE
import imgGirofle_1 from "./image/Clou de Girofle/Girofle_copy.webp";
import imgGirofle_2 from "./image/Clou de Girofle/Girofle_Title_copy.webp";
import imgGirofle_3 from "./image/Clou de Girofle/Girofle_clou.webp";
import imgGirofle_4 from "./image/Clou de Girofle/Girofle_Poudre.webp";
import imgGirofle_5 from "./image/Clou de Girofle/Girofle_clou_3.webp";
import imgGirofle_7 from "./image/Clou de Girofle/Girofle_Clou_5.webp";
import imgGirofle_8 from "./image/Clou de Girofle/Girofle_clou_2.webp";
import imgGirofle_9 from "./image/Clou de Girofle/Girofle_clou4.webp";
import imgGirofle_10 from "./image/Clou de Girofle/Girofle_Clou2.webp";
import imgGirofle_11 from "./image/Clou de Girofle/Girofle_Poudre2.webp";
//COMBOVA
import imgCombova_1 from "./image/Combava/Combava1_copy.webp";
import imgCombova_2 from "./image/Combava/Combava_Title_copy.webp";
import imgCombova_12 from "./image/Combava/Combava_copy.webp";
//ZESTE
import imgCombovaZeste_1 from "./image/Combava/Zeste_de_combava_copy.webp";
import imgCombovaZeste_2 from "./image/Combava/Zeste_de_combava_title_copy.webp";
import imgCombova_11 from "./image/Combava/Combava_Zest_poudre.webp";
import imgCombova_6 from "./image/Combava/Combava_Zest_poudre2.webp";
import imgCombova_10 from "./image/Combava/Combava_Zest_poudre_3.webp";
//CORIANDRE
import imgCoriandre_1 from "./image/Coriandre/Coriandre_copy.webp";
import imgCoriandre_2 from "./image/Coriandre/Coriandre_title_copy.webp";
import imgCoriandre_5 from "./image/Coriandre/Coriandre_1.webp";
import imgCoriandre_9 from "./image/Coriandre/Coriandre2.webp";
//COROSSOL
import imgCorossol_1 from "./image/Corossol/Corossol_copy.webp";
import imgCorossol_2 from "./image/Corossol/Corossol_Title_copy.webp";
//CUMIN
import imgCumin_1 from "./image/Cumin/Cumin_copy.webp";
import imgCumin_2 from "./image/Cumin/Cumin_Title_copy.webp";
import imgCumin_3 from "./image/Cumin/Cumin_grain2.webp";
import imgCumin_5 from "./image/Cumin/Cumin_Grain_2.webp";
import imgCumin_7 from "./image/Cumin/Cumin_grain_3.webp";
import imgCumin_8 from "./image/Cumin/Cumin_Grain.webp";
import imgCumin_11 from "./image/Cumin/Cumin_Poudre.webp";
//CURRY
import imgCurry_1 from "./image/Curry Viande/Curry_Viande_copy.webp";
import imgCurry_2 from "./image/Curry Viande/Curry_Viande_Title_copy.webp";
import imgCurry_3 from "./image/Curry Viande/Curry_Viande.webp";
import imgCurry_6 from "./image/Curry Viande/Curry_Viande_3.webp";
import imgCurry_7 from "./image/Curry Viande/Curry_Viande_4_copy.webp";
import imgCurry_8 from "./image/Curry Viande/Curry_viande_2.webp";
//GUACAMOLE
import imgGuacamole_2 from "./image/Epices Guacamole/Epices_guacamole_Title_copy.webp";
import imgGuacamole_1 from "./image/Epices Guacamole/Epices_guacamole_copy.webp";
import imgGuacamole_3 from "./image/Epices Guacamole/Guacamole.webp";
import imgGuacamole_6 from "./image/Epices Guacamole/Guacamole_3.webp";
import imgGuacamole_9 from "./image/Epices Guacamole/Guacamole2.webp";
//FENUGREC
import imgFenugrec_1 from "./image/FENUGREC/Funegrec_copy.webp";
import imgFenugrec_2 from "./image/FENUGREC/Funegrec_Title_copy.webp";
//FDS
import imgFds_1 from "./image/Fleur de Sel/Fleur_de_Sel_copy.webp";
import imgFds_2 from "./image/Fleur de Sel/Fleur_de_Sel_title_copy.webp";
import imgFdsC_G_1 from "./image/Fleur de Sel/Fleu_de_sel_Combava_gingembre_2.webp";
import imgFdsC_G_2 from "./image/Fleur de Sel/Fleur_de_Sel_Combava_ginger.webp";
import imgFdsV_B_1 from "./image/Fleur de Sel/Fleu_de_sel_Voatsiperifery_blanc.webp";
import imgFdsV_B_2 from "./image/Fleur de Sel/Fleur_De_Sel_voatsiperifery_Blanc1.webp";
import imgFdsV_B_3 from "./image/Fleur de Sel/Fleur_de_SEL_vOATSY_bLANC.webp";
import imgFdsB_P_1 from "./image/Fleur de Sel/Fleur_de_sel_baie_rose_poivre.webp";
import imgFdsB_P_2 from "./image/Fleur de Sel/Fleur_de_Sel_Baie_Rose_et_poivre.webp";
import imgFdsB_1 from "./image/Fleur de Sel/Fleur_de_Sel_Baie_Rose1.webp";
import imgFdsB_2 from "./image/Fleur de Sel/Fleur_de_Sel_baie_rose.webp";
import imgFdsC_1 from "./image/Fleur de Sel/Fleur_De_Sel_Combava_Poudre_2.webp";
import imgFdsC_2 from "./image/Fleur de Sel/Fleur_De_Sel_Combava_Poudre1.webp";
import imgFdsC_3 from "./image/Fleur de Sel/Fleur_de_Sel_Combava_poudre.webp";
import imgFdsG_1 from "./image/Fleur de Sel/Fleur_De_Sel_Gingembre1.webp";
import imgFdsV_1 from "./image/Fleur de Sel/Fleur_De_Sel_Vanille_3.webp";
import imgFdsV_2 from "./image/Fleur de Sel/Fleur_de_Sel_Vanille_2.webp";
import imgFdsV_3 from "./image/Fleur de Sel/Fleur_de_Sel_Vanille.webp";

//MASSALA
import imgMassala_1 from "./image/Garam Massala/MASSALA_copy.webp";
import imgMassala_2 from "./image/Garam Massala/MASSALA_Title_copy.webp";
import imgMassala_4 from "./image/Garam Massala/Massala.webp";
import imgMassala_11 from "./image/Garam Massala/Massala_Poudre.webp";
//GINGEMBRE
import imgGingembre_1 from "./image/Gingembre/Gingembre_copy.webp";
import imgGingembre_2 from "./image/Gingembre/Gingembre_Title_copy.webp";
import imgGingembre_4 from "./image/Gingembre/Gingembre_poudre.webp";
import imgGingembre_6 from "./image/Gingembre/Gingembre_poudre_3.webp";
import imgGingembre_11 from "./image/Gingembre/Gigembre_Poudre.webp";
import imgGingembre_10 from "./image/Gingembre/Gingembre_poudre2.webp";
//MELANGE 3
import imgMelange3Baie_1 from "./image/Mélange 3 baie/Melange_de_3_baies_copy.webp";
import imgMelange3Baie_2 from "./image/Mélange 3 baie/Melange_de_3_baies_Title_copy.webp";
import imgMelange3Baie_3 from "./image/Mélange 3 baie/Melange_3_baie.webp";
import imgMelange3Baie_5 from "./image/Mélange 3 baie/Melange_3_baie_3.webp";
import imgMelange3Baie_7 from "./image/Mélange 3 baie/Melange_3_baie_4.webp";
import imgMelange3Baie_8 from "./image/Mélange 3 baie/Melange_3_baie_4_copy.webp";
import imgMelange3Baie_11 from "./image/Mélange 3 baie/Melange_3_baie_3_copy.webp";
import imgMelange3Baie_12 from "./image/Mélange 3 baie/Melange_3_baie_2.webp";
import imgMelange3Baie_13 from "./image/Mélange 3 baie/Melange_3_baie_2_copy.webp";
//MELANGE 4
import imgMelange4Epice_1 from "./image/Melange 4 Epices/Mélange_4_épices_copy.webp";
import imgMelange4Epice_2 from "./image/Melange 4 Epices/Mélange_4_épices_Title_copy.webp";
import imgMelange4Epice_12 from "./image/Melange 4 Epices/melange_4_epices_copy.webp";
import imgMelange4Epice_6 from "./image/Melange 4 Epices/Melange_4_épices.webp";

//MORINGA
import imgMoringa_1 from "./image/Moringa/Moringa_copy.webp";
import imgMoringa_2 from "./image/Moringa/Moringa_Title_copy.webp";
//MUSCADE
import imgMuscade_1 from "./image/Muscade/Muscade_copy.webp";
import imgMuscade_2 from "./image/Muscade/Muscade_Title_copy.webp";
import imgMuscade_5 from "./image/Muscade/Noix_de_muscade_2.webp";
import imgMuscade_9 from "./image/Muscade/Muscade_Noix.webp";
import imgMuscade_11 from "./image/Muscade/Muscade_Poudre.webp";
import imgMuscade_7 from "./image/Muscade/Noix_de_muscade_3.webp";
import imgMuscade_10 from "./image/Muscade/Noix_de_muscade.webp";
//PIMENT ROUGE
import imgPimentRouge_1 from "./image/Piment rouge/Piment_Rouge_copy.webp";
import imgPimentRouge_2 from "./image/Piment rouge/Piment_Rouge_Title_copy.webp";
import imgPimentRouge_7 from "./image/Piment rouge/Piment_rouge.webp";
//PIMENT VERT
import imgPimentVert_1 from "./image/Piment Vert/Piment_vert_copy.webp";
import imgPimentVert_2 from "./image/Piment Vert/Piment_vert_title_copy.webp";
import imgPimentVert_3 from "./image/Piment Vert/Piment_vert_entier_2.webp";
import imgPimentVert_5 from "./image/Piment Vert/Piment_vert_Poudre_4.webp";
import imgPimentVert_6 from "./image/Piment Vert/Piment_vert_Poudre.webp";
import imgPimentVert_7 from "./image/Piment Vert/Piment_vert_2.webp";
import imgPimentVert_8 from "./image/Piment Vert/Piment_vert_entier.webp";
import imgPimentVert_10 from "./image/Piment Vert/Piment_vert_entier2.webp";
import imgPimentVert_11 from "./image/Piment Vert/Piment_vert_Poudre_5.webp";
//POIVRE BLANC
import imgPoivreBlanc_1 from "./image/Poivre blanc/Povre_blanc_copy.webp";
import imgPoivreBlanc_2 from "./image/Poivre blanc/Povre_blanc_title_copy.webp";
import imgPoivreBlanc_4 from "./image/Poivre blanc/Poivre_Blanc_Moulu_2.webp";
import imgPoivreBlanc_5 from "./image/Poivre blanc/Poivre_Blanc_grain.webp";
import imgPoivreBlanc_6 from "./image/Poivre blanc/Poivre_Blanc_Poudre.webp";
import imgPoivreBlanc_7 from "./image/Poivre blanc/Poivre_blanc_Grain_2.webp";
import imgPoivreBlanc_11 from "./image/Poivre blanc/Poivre_Blanc_Moulu.webp";
import imgPoivreBlanc_8 from "./image/Poivre blanc/Poivre_blanc.webp";
import imgPoivreBlanc_9 from "./image/Poivre blanc/Poivre_Moulu.webp";

//POIVRE NOIR
import imgPoivreNoir_1 from "./image/Poivre Noir/Poivre_Noir_copy.webp";
import imgPoivreNoir_2 from "./image/Poivre Noir/imgPoivreNoire_1.webp";
import imgPoivreNoir_8 from "./image/Poivre Noir/Poivre_Noir_2.webp";
import imgPoivreNoir_3 from "./image/Poivre Noir/Poivre_Noir_concassé_2.webp";
import imgPoivreNoir_7 from "./image/Poivre Noir/Poivre_Noir_Concassé_3.webp";
import imgPoivreNoir_9 from "./image/Poivre Noir/Poivre_Noir_concassé_copy.webp";
import imgPoivreNoir_10 from "./image/Poivre Noir/Poivre_Noir_Grain_4.webp";
import imgPoivreNoir_4 from "./image/Poivre Noir/Poivre_Noir_Grain.webp";
import imgPoivreNoir_12 from "./image/Poivre Noir/Poivre_Noir_Grain2.webp";
import imgPoivreNoir_13 from "./image/Poivre Noir/Poivre_noir_grain3.webp";
import imgPoivreNoir_14 from "./image/Poivre Noir/Poivre_Noir_Moulu.webp";
import imgPoivreNoir_6 from "./image/Poivre Noir/Poivre_Noir_Poudre_2.webp";
import imgPoivreNoir_5 from "./image/Poivre Noir/Poivre_Noir.webp";
//TSILANDIMILAHY
import imgTsilandimilahy_1 from "./image/Tsilandimilahy/Piment_tsilandimilahy_copy.webp";
import imgTsilandimilahy_2 from "./image/Tsilandimilahy/Piment_tsilandimilahy_Title_copy.webp";
//VANILLE
import imgVanille_1 from "./image/Vanille/Vanille_copy.webp";
import imgVanille_2 from "./image/Vanille/Vanille_Title_copy.webp";
import imgVanille_3 from "./image/Vanille/Vanille_poudre2.webp";
import imgVanille_6 from "./image/Vanille/Vanille_Poudre.webp";
import imgVanille_7 from "./image/Vanille/Vanille_gousse.webp";
import imgVanille_10 from "./image/Vanille/Vanille_poudre1.webp";
//VOATSY ROUGE
import imgVoatsiperiferyRouge_1 from "./image/Voatsiperifery Rouge/Votsiperifery_rouge_copy.webp";
import imgVoatsiperiferyRouge_2 from "./image/Voatsiperifery Rouge/Votsiperifery_rouge_Title_copy.webp";
import imgVoatsiperiferyRouge_3 from "./image/Voatsiperifery Rouge/Voatsiperifery_Rouge_5.webp";
import imgVoatsiperiferyRouge_5 from "./image/Voatsiperifery Rouge/Voatsiperifery_Rouge2.webp";
import imgVoatsiperiferyRouge_8 from "./image/Voatsiperifery Rouge/Voatsiperifery_Rouge.webp";
import imgVoatsiperiferyRouge_7 from "./image/Voatsiperifery Rouge/Voatsiperifery_Rouge3.webp";
import imgVoatsiperiferyRouge_10 from "./image/Voatsiperifery Rouge/Voatsiperifery_Rouge4.webp";
import imgVoatsiperiferyRouge_9 from "./image/Voatsiperifery Rouge/Poivre_Rouge_Poudre.webp";

//VOATSY BLANC
import imgVoatsiperiferyBlanc_1 from "./image/Votsiperifery Blanc/Voatsiperifery_blanc_copy.webp";
import imgVoatsiperiferyBlanc_2 from "./image/Votsiperifery Blanc/Voatsiperifery_blanc_Title_copy.webp";
import imgVoatsiperiferyBlanc_5 from "./image/Votsiperifery Blanc/Vaotsiperifery_Blanc.webp";
import imgVoatsiperiferyBlanc_8 from "./image/Votsiperifery Blanc/Voatsiperifery_Blanc_Grain.webp";
import imgVoatsiperiferyBlanc_7 from "./image/Votsiperifery Blanc/Voatsiperifery_Blanc_Grain_2.webp";
import imgVoatsiperiferyBlanc_12 from "./image/Votsiperifery Blanc/poivre-sauvage-voatsiperifery-madagascar-blanc-comptoir-des-poivres copy.webp";

//VOATSY NOIR
import imgVoatsiperiferyNoir_1 from "./image/Votsiperifery Noir/Voatsiperifery_Noir_copy.webp";
import imgVoatsiperiferyNoir_2 from "./image/Votsiperifery Noir/Voatsiperifery_Noir_Title_copy.webp";
import imgVoatsiperiferyNoir_7 from "./image/Votsiperifery Noir/Voatsiperifery_Noir_2.webp";
import imgVoatsiperiferyNoir_3 from "./image/Votsiperifery Noir/Voatsiperifery_Noir_Grain.webp";
import imgVoatsiperiferyNoir_8 from "./image/Votsiperifery Noir/Voatsiperifery_Noir_Grain2.webp";
import imgVoatsiperiferyNoir_5 from "./image/Votsiperifery Noir/Voatsiperifery_Noir_grain3.webp";
import imgVoatsiperiferyNoir_9 from "./image/Votsiperifery Noir/Voatsiperifery_Noir_Moulu.webp";
import imgVoatsiperiferyNoir_4 from "./image/Votsiperifery Noir/Voatsiperifery_Noir_Poudre.webp";
import imgVoatsiperiferyNoir_6 from "./image/Votsiperifery Noir/Voatsiperifery_Noir_Poudre2.webp";
//ANANAS
import imgAnanas_1 from "./image/Fruits/Ananas/Ananas_copy.webp";
import imgAnanas_2 from "./image/Fruits/Ananas/Ananas_title_copy.webp";
//COCO
import imgCoco_1 from "./image/Fruits/Coco/Coco_copy.webp";
import imgCoco_2 from "./image/Fruits/Coco/Coco_Title_copy.webp";
//JACQUES
import imgJacques_1 from "./image/Fruits/Jaques/Jaques_copy.webp";
import imgJacques_2 from "./image/Fruits/Jaques/Jaques_title_copy.webp";
//LITCHI
import imgLitchi_1 from "./image/Fruits/Litchis/Litchis_copy.webp";
import imgLitchi_2 from "./image/Fruits/Litchis/Litchis_Title_copy.webp";
//MANGUE
import imgMangue_1 from "./image/Fruits/Mangue/Mangue_copy.webp";
import imgMangue_2 from "./image/Fruits/Mangue/Mangue_title_copy.webp";
//POK POK
import imgPokpok_1 from "./image/Fruits/Pok pok/Pok_Pok_copy.webp";
import imgPokpok_2 from "./image/Fruits/Pok pok/Pok_Pok_title_copy.webp";

export const data = [
  //AIL
  {
    id: 1,
    name: "AIL",
    description:
      "L'ail est un ingrédient polyvalent et savoureux utilisé dans nombreuses cuisines du monde entier. Il peut être consommé cru, cuit ou séché.",
    imgUrl: imgAil_1,
    imgUrl_1: imgAil_2,
    type: "Epice",
    phare: true,
    produits: [
      {
        id: 1,
        name: "AIL MORCEAU",
        conditionnement: [
          { unite: "vrac(1kg-5kg)" },
          { unite: "Doypack", grammage: "300g" },
          { unite: "verrine", grammage: "25g" },
          { unite: "SB", grammage: "100g" },
          { unite: "PET", grammage: "25g" },
        ],
        description:
          "L'ail est un ingrédient polyvalent et savoureux utilisé dans nombreuses cuisines du monde entier. Il peut être consommé cru, cuit ou séché.",
        imgUrl: imgAil_3,
        gallery: [imgAil_3, imgAil_5],
        texture: "morceau",
        gout: "piquant",
        intensite: "Forte",
        parfum: "Arôme d'allicine",
        origine: "Madagascar",
        conservation: "endroit frais et sec",
        composition:
          "vitamines B et C, potassium, calcium, fer, composés sulfurés.",
      },
      {
        id: 2,
        name: "AIL POUDRE",
        conditionnement: [
          { unite: "vrac(1kg-5kg)" },
          { unite: "Doypack", grammage: "120g" },
          { unite: "verrine", grammage: "25g" },
          { unite: "SB", grammage: "100g" },
        ],
        description:
          "L'ail est un ingrédient polyvalent et savoureux utilisé dans nombreuses cuisines du monde entier. Il peut être consommé cru, cuit ou séché.",
        imgUrl: imgAil_4,
        gallery: [imgAil_4, imgAil_6],
        texture: "poudre",
        gout: "piquant",
        intensite: "Forte",
        parfum: "Arôme d'allicine",
        origine: "Madagascar",
        conservation: "endroit frais et sec",
        composition:
          "vitamines B et C, potassium, calcium, fer, composés sulfurés.",
      },
    ],
  },
  //ANIS ETOILE
  {
    id: 2,
    name: "ANIS ETOILE",
    description:
      "L'anis étoilé est une épice polyvalente avec une saveur distinctive d'anis. Cette épice est utilisée dans la cuisine et la préparation de boissons dans de nombreuses cultures.",
    imgUrl: imgAnis_1,
    imgUrl_1: imgAnis_2,
    type: "Epice",
    phare: true,
    produits: [
      {
        id: 1,
        name: "ANIS ETOILE",
        conditionnement: [{ unite: "vrac(1kg-5kg)" }],
        description:
          "L'anis étoilé est une épice polyvalente avec une saveur distinctive d'anis. Cette épice est utilisée dans la cuisine et la préparation de boissons dans de nombreuses cultures.",
        imgUrl: imgAnis_5,
        gallery: [imgAnis_5],
        texture: "entiere",
        gout: "sucrée et piquante",
        intensite: "Légère",
        parfum: " Doux et pénétrant",
        origine: "Madagascar",
        conservation: "endroit frais et sec, à l'abri de la lumière",
        composition:
          "huile essentielle, anethole, antioxydants, vitamines (B et C), minéraux (calcium, fer)",
      },
      {
        id: 2,
        name: "ANIS ETOILE POUDRE",
        conditionnement: [{ unite: "vrac(1kg-5kg)" }],
        description:
          "L'anis étoilé est une épice polyvalente avec une saveur distinctive d'anis. Cette épice est utilisée dans la cuisine et la préparation de boissons dans de nombreuses cultures.",
        imgUrl: imgAnis_5,
        gallery: [imgAnis_5],
        texture: "poudre",
        gout: "sucrée et piquante",
        intensite: "Légère",
        parfum: " Doux et pénétrant",
        origine: "Madagascar",
        conservation: "endroit frais et sec, à l'abri de la lumière",
        composition:
          "huile essentielle, anethole, antioxydants, vitamines (B et C), minéraux (calcium, fer)",
      },
    ],
  },
  //BAIE ROSE
  {
    id: 3,
    name: "BAIE ROSE",
    description:
      "La baie rose, également connue sous le nom de poivre rose, est souvent utilisée pour aromatiser ou décorer de nombreux plats.",
    imgUrl: imgBaie_1,
    imgUrl_1: imgBaie_2,
    type: "Epice",
    phare: true,
    produits: [
      {
        id: 1,
        name: "BAIE ROSE",
        conditionnement: [
          { unite: "vrac(1kg-5kg)" },
          { unite: "Doypack", grammage: "100g" },
          { unite: "verrine", grammage: "13g" },
          { unite: "SB", grammage: "50g" },
          { unite: "PET", grammage: "12g" },
          { unite: "Eprouvette", grammage: "22g" },
        ],
        description:
          "La baie rose, également connue sous le nom de poivre rose, est souvent utilisée pour aromatiser ou décorer de nombreux plats.",
        imgUrl: imgBaie_3,
        gallery: [imgBaie_3, imgBaie_5, imgBaie_7, imgBaie_8],
        texture: "lisse et ferme",
        gout: "Fruité avec de notes poivrées",
        intensite: "Douce, moins piquante que le poivre noir",
        parfum: "agréable et délicat",
        origine: "Madagascar",
        conservation:
          "récipient hermetique, à l'abri de la chaleur et de l'humidité.",
        composition:
          "Vitamine C, potassium, calcium, magnésium, fibres, antioxydants",
      },
    ],
  },
  //BAOBAB
  {
    id: 4,
    name: "BAOBAB",
    description:
      "La poudre de baobab est un produit alimentaire dérivé  de la pulpe séchée du fruit du baobab. Elle peut être utilisée dans de nombreuses préparations culinaires.",
    imgUrl: imgBaobab_1,
    imgUrl_1: imgBaobab_2,
    type: "Epice",
    phare: true,
    produits: [
      {
        id: 1,
        name: "BAOBAB EN POUDRE",
        conditionnement: [
          { unite: "vrac(1kg-5kg)" },
          { unite: "Doypack", grammage: "100g" },
          { unite: "verrine", grammage: "13g" },
          { unite: "SB", grammage: "100g" },
          { unite: "PET", grammage: "15g" },
          { unite: "Eprouvette", grammage: "25g" },
        ],
        description:
          "La poudre de baobab est un produit alimentaire dérivé  de la pulpe séchée du fruit du baobab. Elle peut être utilisée dans de nombreuses préparations culinaires.",
        imgUrl: imgBaobab_6,
        gallery: [imgBaobab_6],
        texture: "poudre",
        gout: "Acidulé avec des notes de fruits tropicaux",
        intensite: "Légère",
        parfum: "agréable et fruitée",
        origine: "Madagascar",
        conservation:
          "récipient hermetique, à l'abri de la chaleur et de l'humidité.",
        composition:
          "Vitamine C, potassium, calcium, magnésium, fibres, antioxydants",
      },
    ],
  },
  //BOIS D'ANIS
  {
    id: 5,
    name: "BOIS D'ANIS",
    description:
      "Le bois d'anis est la partie séchée de l'arbre du même nom. Il est largement utilisé comme épice dans la cuisine asiatique. Il peut également être utilisé pour aromatiser les boissons alcoolisées, telles que l'absinthe et le pastis.",
    imgUrl: imgBoisAnis_1,
    imgUrl_1: imgBoisAnis_2,
    type: "Epice",
    phare: true,
    produits: [
      {
        id: 1,
        name: "BOIS D'ANIS",
        conditionnement: [{ unite: "vrac(1kg-5kg)" }],
        description:
          "Le bois d'anis est la partie séchée de l'arbre du même nom. Il est largement utilisé comme épice dans la cuisine asiatique. Il peut également être utilisé pour aromatiser les boissons alcoolisées, telles que l'absinthe et le pastis.",
        imgUrl: imgBoisAnis_1,
        gallery: [imgBoisAnis_1],
        texture: "ecorce",
        gout: "Epicé avec des notes d'anis et de réglisse",
        intensite: "Douce",
        parfum: "Aromatique et distinctif",
        origine: "Madagascar",
        conservation:
          "récipient hermetique, à l'abri de la lumière et de l'humidité",
        composition: "anéthole, antioxydants",
      },
    ],
  },
  //CACAO FEVE
  {
    id: 6,
    name: "FEVE DE CACAO ",
    description:
      "La fève de cacao est la graine du fruit du cacaoyer. Elle est souvent utilisée pour la fabrication du chocolat et également dans la préparation de boissons chaudes au chocolat, de desserts, de pâtisseries, etc.",
    imgUrl: imgCacao_1,
    imgUrl_1: imgCacao_2,
    type: "Epice",
    phare: true,
    produits: [
      {
        id: 1,
        name: "FEVE DE CACAO BRUT",
        conditionnement: [{ unite: "vrac(1kg-5kg)" }],
        description:
          "La fève de cacao est la graine du fruit du cacaoyer. Elle est souvent utilisée pour la fabrication du chocolat et également dans la préparation de boissons chaudes au chocolat, de desserts, de pâtisseries, etc.",
        imgUrl: imgCacao_7,
        gallery: [imgCacao_7],
        texture: "brute",
        gout: "Amer avec des notes de chocolat",
        intensite: "Intense",
        parfum: "Chocolat",
        origine: "Madagascar",
        conservation:
          "endroit frais et sec, à l'abri de l'humidité et de la lumière",
        composition:
          "fibres, magnésium, fer, potassium, antioxydants, flavonoïdes",
      },
      {
        id: 2,
        name: "CACAO FEVE TORREFIEE",
        conditionnement: [
          { unite: "vrac(1kg-5kg)" },
          { unite: "verrine", grammage: "30g" },
          { unite: "Eprouvette", grammage: "40g" },
        ],
        description:
          "La fève de cacao est la graine du fruit du cacaoyer. Elle est souvent utilisée pour la fabrication du chocolat et également dans la préparation de boissons chaudes au chocolat, de desserts, de pâtisseries, etc.",
        imgUrl: imgCacao_7,
        gallery: [imgCacao_7],
        texture: "torrefiee",
        gout: "Amer avec des notes de chocolat",
        intensite: "Intense",
        parfum: "Chocolat",
        origine: "Madagascar",
        conservation:
          "endroit frais et sec, à l'abri de l'humidité et de la lumière",
        composition:
          "fibres, magnésium, fer, potassium, antioxydants, flavonoïdes",
      },
      {
        id: 3,
        name: "CACAO FEVE CONCASSEE",
        conditionnement: [{ unite: "vrac(1kg-5kg)" }],
        description:
          "La fève de cacao est la graine du fruit du cacaoyer. Elle est souvent utilisée pour la fabrication du chocolat et également dans la préparation de boissons chaudes au chocolat, de desserts, de pâtisseries, etc.",
        imgUrl: imgCacao_7,
        gallery: [imgCacao_7],
        texture: "concassee",
        gout: "Amer avec des notes de chocolat",
        intensite: "Intense",
        parfum: "Chocolat",
        origine: "Madagascar",
        conservation:
          "endroit frais et sec, à l'abri de l'humidité et de la lumière",
        composition:
          "fibres, magnésium, fer, potassium, antioxydants, flavonoïdes",
      },
    ],
  },
  //CANNELLE
  {
    id: 7,
    name: "CANNELLE",
    description:
      "La cannelle est souvent utilisée dans une variété de plats sucrés et salés comme les desserts, les pâtisseries, les boissons chaudes. Elle peut être saupoudrée sur des fruits, ajoutée à des marinades, incorporée dans des mélanges d'épices, etc.",
    imgUrl: imgCanelle_1,
    imgUrl_1: imgCanelle_2,
    type: "Epice",
    phare: true,
    produits: [
      {
        id: 1,
        name: "CANNELLE POUDRE",
        conditionnement: [
          { unite: "vrac(1kg-5kg)" },
          { unite: "Doypack", grammage: "80g" },
          { unite: "verrine", grammage: "16g" },
          { unite: "SB", grammage: "100g" },
          { unite: "PET", grammage: "18g" },
          { unite: "Eprouvette", grammage: "36g" },
        ],
        description:
          "La cannelle est souvent utilisée dans une variété de plats sucrés et salés comme les desserts, les pâtisseries, les boissons chaudes. Elle peut être saupoudrée sur des fruits, ajoutée à des marinades, incorporée dans des mélanges d'épices, etc.",
        imgUrl: imgCanelle_8,
        gallery: [imgCanelle_4, imgCanelle_8, imgCanelle_6, imgCanelle_7],
        texture: "poudre",
        gout: "Sucré",
        intensite: "Doux",
        parfum: "Chaud et épicé",
        origine: "Madagascar",
        conservation:
          "endroit frais, sec et à l'abri de la lumière directe du soleil",
        composition:
          "antioxydants, anti-inflammatoires, antifongiques naturels, fibres, calcium, fer, vitamine K",
      },
      {
        id: 2,
        name: "CANNELLE ROULEAU 18/10 CM",
        conditionnement: [
          { unite: "vrac(1kg-5kg)" },
          { unite: "SB", grammage: "100g" },
        ],
        description:
          "La cannelle est souvent utilisée dans une variété de plats sucrés et salés comme les desserts, les pâtisseries, les boissons chaudes. Elle peut être saupoudrée sur des fruits, ajoutée à des marinades, incorporée dans des mélanges d'épices, etc.",
        imgUrl: imgCanelle_5,
        gallery: [imgCanelle_5],
        texture: "rouleau",
        gout: "Sucré",
        intensite: "Doux",
        parfum: "Chaud et épicé",
        origine: "Madagascar",
        conservation:
          "endroit frais, sec et à l'abri de la lumière directe du soleil",
        composition:
          "antioxydants, anti-inflammatoires, antifongiques naturels, fibres, calcium, fer, vitamine K",
      },
      {
        id: 3,
        name: "CANNELLE ROULEAU 18/20 CM",
        conditionnement: [{ unite: "vrac(1kg-5kg)" }],
        description:
          "La cannelle est souvent utilisée dans une variété de plats sucrés et salés comme les desserts, les pâtisseries, les boissons chaudes. Elle peut être saupoudrée sur des fruits, ajoutée à des marinades, incorporée dans des mélanges d'épices, etc.",
        imgUrl: imgCanelle_5,
        gallery: [imgCanelle_5],
        texture: "rouleau",
        gout: "Sucré",
        intensite: "Doux",
        parfum: "Chaud et épicé",
        origine: "Madagascar",
        conservation:
          "endroit frais, sec et à l'abri de la lumière directe du soleil",
        composition:
          "antioxydants, anti-inflammatoires, antifongiques naturels, fibres, calcium, fer, vitamine K",
      },
    ],
  },
  //CITRONNELLE
  {
    id: 8,
    name: "CITRONNELLE",
    description:
      "La citronnelle est fréquemment utilisée dans la cuisine asiatique, en particulier dans les plats thaïlandais et vietnamiens. Elle ajoute une saveur citronnée et une note herbacée aux soupes, aux curry, aux marinades, aux sauces et aux boissons.",
    imgUrl: imgCitronelle_1,
    imgUrl_1: imgCitronelle_2,
    type: "Epice",
    phare: true,
    produits: [
      {
        id: 1,
        name: "CITRONNELLE MORCEAU",
        conditionnement: [
          { unite: "vrac(1kg-5kg)" },
          { unite: "SB", grammage: "50g" },
        ],
        description:
          "La citronnelle est fréquemment utilisée dans la cuisine asiatique, en particulier dans les plats thaïlandais et vietnamiens. Elle ajoute une saveur citronnée et une note herbacée aux soupes, aux curry, aux marinades, aux sauces et aux boissons.",
        imgUrl: imgCitronelle_5,
        gallery: [imgCitronelle_5],
        texture: "tiges fines",
        gout: "Citronné",
        intensite: "",
        parfum: "Citronné, frais et aromatique",
        origine: "Madagascar",
        conservation:
          "endroit frais, sec et à l'abri de la lumière directe du soleil",
        composition:
          "citronellols, citronnellals, limonènes, antioxydants, vitamine C,  vitamine A, fer, potassium.",
      },
    ],
  },
  //FEUILLE DE COMBAVA
  {
    id: 9,
    name: "FEUILLE DE COMBAVA",
    description:
      "Les feuilles de combava sont utilisées dans de nombreux plats asiatiques, tels que les currys, les soupes, les marinades et les sauces. Elles peuvent être utilisées fraîches ou séchées, et sont souvent retirées avant de servir le plat.",
    imgUrl: imgCombova_1,
    imgUrl_1: imgCombova_2,
    type: "Epice",
    phare: true,
    produits: [
      {
        id: 1,
        name: "FEUILLE DE COMBAVA",
        conditionnement: [{ unite: "vrac(1kg-5kg)" }],
        description:
          "Les feuilles de combava sont utilisées dans de nombreux plats asiatiques, tels que les currys, les soupes, les marinades et les sauces. Elles peuvent être utilisées fraîches ou séchées, et sont souvent retirées avant de servir le plat.",
        imgUrl: imgCombova_12,
        gallery: [imgCombova_10, imgCombova_2],
        texture: "feuille",
        gout: "Acidulé",
        intensite: "Intense",
        parfum: "Citronné",
        origine: "Madagascar",
        conservation:
          "endroit frais, sec et à l'abri de la lumière directe du soleil",
        composition: "",
      },
      {
        id: 2,
        name: "COMBAVA FEUILLE POUDRE",
        conditionnement: [{ unite: "vrac(1kg-5kg)" }],
        description:
          "Les feuilles de combava sont utilisées dans de nombreux plats asiatiques, tels que les currys, les soupes, les marinades et les sauces. Elles peuvent être utilisées fraîches ou séchées, et sont souvent retirées avant de servir le plat.",
        imgUrl: imgCombova_12,
        gallery: [imgCombova_10, imgCombova_11, imgCombova_6],
        texture: "poudre",
        gout: "Acidulé",
        intensite: "Intense",
        parfum: "Citronné",
        origine: "Madagascar",
        conservation:
          "endroit frais, sec et à l'abri de la lumière directe du soleil",
        composition: "",
      },
    ],
  },
  //COMBAVA ZESTE
  {
    id: 10,
    name: "ZESTE DE COMBAVA",
    description:
      "Les zestes de combava sont utilisés pour parfumer les plats sucrés et salés, tels que les pâtisseries, les desserts, les marinades, les cocktails et les sauces. Ils sont également utilisés dans la préparation de thés et d'infusions. ",
    imgUrl: imgCombovaZeste_1,
    imgUrl_1: imgCombovaZeste_2,
    type: "Epice",
    phare: true,
    produits: [
      {
        id: 1,
        name: "ZESTE DE COMBAVA",
        conditionnement: [{ unite: "vrac(1kg-5kg)" }],
        description:
          "Les zestes de combava sont utilisés pour parfumer les plats sucrés et salés, tels que les pâtisseries, les desserts, les marinades, les cocktails et les sauces. Ils sont également utilisés dans la préparation de thés et d'infusions. ",
        imgUrl: imgCombova_12,
        gallery: [imgCombova_12],
        texture: "zeste",
        gout: " Aromatique ",
        intensite: "Intense",
        parfum: "Citronné",
        origine: "Madagascar",
        conservation:
          "endroit frais, sec et à l'abri de la lumière directe du soleil",
        composition:
          "protéines, lipides , glucides , vitamine C, calcium, potassium , fer , magnésium.",
      },
      {
        id: 2,
        name: "ZESTE DE COMBAVA EN POUDRE",
        conditionnement: [
          { unite: "vrac(1kg-5kg)" },
          { unite: "Doypack", grammage: "90g" },
          { unite: "verrine", grammage: "17g" },
          { unite: "SB", grammage: "70g" },
          { unite: "PET", grammage: "18g" },
          { unite: "Eprouvette", grammage: "40g" },
        ],
        description:
          "Les zestes de combava sont utilisés pour parfumer les plats sucrés et salés, tels que les pâtisseries, les desserts, les marinades, les cocktails et les sauces. Ils sont également utilisés dans la préparation de thés et d'infusions. ",
        imgUrl: imgCombova_11,
        gallery: [imgCombova_11, imgCombova_6, imgCombova_10],
        texture: "poudre",
        gout: " Aromatique ",
        intensite: "Intense",
        parfum: "Citronné",
        origine: "Madagascar",
        conservation:
          "endroit frais, sec et à l'abri de la lumière directe du soleil",
        composition:
          "protéines, lipides , glucides , vitamine C, calcium, potassium , fer , magnésium.",
      },
    ],
  },
  //CORIANDRE
  {
    id: 11,
    name: "CORIANDRE",
    description:
      "Les graines de coriandre sont utilisées dans de nombreuses cuisines du monde entier pour aromatiser les plats, tandis que les graines moulues sont couramment utilisées dans les mélanges d'épices, les sauces, les soupes et les saucisses.",
    imgUrl: imgCoriandre_1,
    imgUrl_1: imgCoriandre_2,
    type: "Epice",
    phare: true,
    produits: [
      {
        id: 1,
        name: "GRAINE DE CORIANDRE",
        conditionnement: [
          { unite: "vrac(1kg-5kg)" },
          { unite: "Doypack", grammage: "120g" },
          { unite: "verrine", grammage: "16g" },
          { unite: "SB", grammage: "100g" },
          { unite: "PET", grammage: "15g" },
          { unite: "Eprouvette", grammage: "25g" },
        ],
        description:
          "Les graines de coriandre sont utilisées dans de nombreuses cuisines du monde entier pour aromatiser les plats, tandis que les graines moulues sont couramment utilisées dans les mélanges d'épices, les sauces, les soupes et les saucisses.",
        imgUrl: imgCoriandre_5,
        gallery: [imgCoriandre_5, imgCoriandre_9],
        texture: "grain",
        gout: "Citronné avec une note épicée",
        intensite: "Légèrement sucrée ",
        parfum: "Piquant et herbacé",
        origine: "Madagascar",
        conservation:
          "récipient hermetique, à l'abri de la chaleur et de l'humidité.",
        composition: "",
      },
    ],
  },
  //COROSSOL FEUILLE
  {
    id: 12,
    name: "FEUILLE DE COROSSOL",
    description:
      "Les feuilles de corossol sont utilisées dans la préparation de divers plats et boissons, notamment des thés, des infusions et des jus. ",
    imgUrl: imgCorossol_1,
    imgUrl_1: imgCorossol_2,
    type: "Epice",
    phare: true,
    produits: [
      {
        id: 1,
        name: "FEUILLE DE COROSSOL ENTIERE",
        conditionnement: [{ unite: "vrac(1kg-5kg)" }],
        description:
          "Les feuilles de corossol sont utilisées dans la préparation de divers plats et boissons, notamment des thés, des infusions et des jus. ",
        imgUrl: imgCorossol_1,
        gallery: [imgCorossol_2],
        texture: "feuille",
        gout: "Agréable",
        intensite: "",
        parfum: "",
        origine: "Madagascar",
        conservation: "",
        composition: "antioxydants, anti-inflammatoires",
      },
      {
        id: 2,
        name: "FEUILLE DE COROSSOL HACHEE",
        conditionnement: [{ unite: "vrac(1kg-5kg)" }],
        description:
          "Les feuilles de corossol sont utilisées dans la préparation de divers plats et boissons, notamment des thés, des infusions et des jus. ",
        imgUrl: imgCorossol_1,
        gallery: [imgCorossol_2],
        texture: "hachee",
        gout: "Agréable",
        intensite: "",
        parfum: "",
        origine: "Madagascar",
        conservation: "",
        composition: "antioxydants, anti-inflammatoires",
      },
      {
        id: 3,
        name: "FEUILLE DE COROSSOL EN POUDRE",
        conditionnement: [{ unite: "vrac(1kg-5kg)" }],
        description:
          "Les feuilles de corossol sont utilisées dans la préparation de divers plats et boissons, notamment des thés, des infusions et des jus. ",
        imgUrl: imgCorossol_1,
        gallery: [imgCorossol_2],
        texture: "poudre",
        gout: "Agréable",
        intensite: "",
        parfum: "",
        origine: "Madagascar",
        conservation: "",
        composition: "antioxydants, anti-inflammatoires",
      },
    ],
  },
  //CUMIN
  {
    id: 13,
    name: "CUMIN",
    description:
      "Le cumin est largement utilisé notamment dans la cuisine indienne, mexicaine et du Moyen-Orient pour aromatiser les plats, les soupes, les sauces et les marinades. Le cumin moulu est couramment utilisé dans les currys, les tacos et les couscous.",
    imgUrl: imgCumin_1,
    imgUrl_1: imgCumin_2,
    type: "Epice",
    phare: true,
    produits: [
      {
        id: 1,
        name: "GRAIN DE CUMIN",
        conditionnement: [
          { unite: "vrac(1kg-5kg)" },
          { unite: "Doypack", grammage: "200g" },
          { unite: "verrine", grammage: "30g" },
          { unite: "SB", grammage: "100g" },
          { unite: "PET", grammage: "20g" },
          { unite: "Eprouvette", grammage: "40g" },
        ],
        description:
          "Le cumin est largement utilisé notamment dans la cuisine indienne, mexicaine et du Moyen-Orient pour aromatiser les plats, les soupes, les sauces et les marinades. Le cumin moulu est couramment utilisé dans les currys, les tacos et les couscous.",
        imgUrl: imgCumin_3,
        gallery: [imgCumin_3, imgCumin_5, imgCumin_7, imgCumin_8],
        texture: "grain",
        gout: "Chaud, terreux, légèrement amère",
        intensite: "Forte et distinctive",
        parfum: "",
        origine: "Madagascar",
        conservation:
          "endroit frais, sec et à l'abri de la lumière directe du soleil",
        composition: "antioxydants, vitamines",
      },
      {
        id: 2,
        name: "CUMIN EN POUDRE",
        conditionnement: [
          { unite: "vrac(1kg-5kg)" },
          { unite: "verrine", grammage: "17g" },
          { unite: "PET", grammage: "25g" },
        ],
        description:
          "Le cumin est largement utilisé notamment dans la cuisine indienne, mexicaine et du Moyen-Orient pour aromatiser les plats, les soupes, les sauces et les marinades. Le cumin moulu est couramment utilisé dans les currys, les tacos et les couscous.",
        imgUrl: imgCumin_11,
        gallery: [imgCumin_11],
        texture: "poudre",
        gout: "Chaud, terreux, légèrement amère",
        intensite: "Forte et distinctive",
        parfum: "",
        origine: "Madagascar",
        conservation:
          "endroit frais, sec et à l'abri de la lumière directe du soleil",
        composition: "antioxydants, vitamines",
      },
    ],
  },
  //CURCUMA
  {
    id: 14,
    name: "CURCUMA",
    description:
      "Le curcuma est largement utilisé dans la cuisine asiatique, notamment dans les currys, les sauces, les marinades et les mélanges d'épices. Il est également utilisé comme colorant alimentaire naturel.",
    imgUrl: imgCurcuma_1,
    imgUrl_1: imgCurcuma_2,
    type: "Epice",
    phare: true,
    produits: [
      {
        id: 1,
        name: "CURCUMA EN POUDRE",
        conditionnement: [
          { unite: "vrac(1kg-5kg)" },
          { unite: "Doypack", grammage: "120g" },
          { unite: "verrine", grammage: "17g" },
          { unite: "SB", grammage: "100g" },
          { unite: "PET", grammage: "22g" },
          { unite: "Eprouvette", grammage: "39g" },
        ],
        description:
          "Le curcuma est largement utilisé dans la cuisine asiatique, notamment dans les currys, les sauces, les marinades et les mélanges d'épices. Il est également utilisé comme colorant alimentaire naturel.",
        imgUrl: imgCurcuma_6,
        gallery: [imgCurcuma_6, imgCurcuma_4],
        texture: "poudre",
        gout: "Amer et épicé",
        intensite: "Légère",
        parfum: "",
        origine: "Madagascar",
        conservation:
          "endroit frais, sec et à l'abri de la lumière directe du soleil",
        composition: "anti-inflammatoires, antioxydantes",
      },
      {
        id: 2,
        name: "CURCUMA EN MORCEAU",
        conditionnement: [{ unite: "vrac(1kg-5kg)" }],
        description:
          "Le curcuma est largement utilisé dans la cuisine asiatique, notamment dans les currys, les sauces, les marinades et les mélanges d'épices. Il est également utilisé comme colorant alimentaire naturel.",
        imgUrl: imgCurcuma_1,
        gallery: [imgCurcuma_6, imgCurcuma_4],
        texture: "morceau",
        gout: "Amer et épicé",
        intensite: "Légère",
        parfum: "",
        origine: "Madagascar",
        conservation:
          "endroit frais, sec et à l'abri de la lumière directe du soleil",
        composition: "anti-inflammatoires, antioxydantes",
      },
    ],
  },
  //CURRY SPECIAL VIANDE
  {
    id: 16,
    name: "CURRY SPECIAL VIANDE",
    description:
      "Le curry spécial viande est un mélange d'épices utilisé pour préparer des plats de viande savoureux et épicés. Il est généralement composé d'un mélange d'épices telles que le curcuma, le cumin, la coriandre, le gingembre, le poivre, la cannelle, la cardamome et le clou de girofle. ",
    imgUrl: imgCurry_1,
    imgUrl_1: imgCurry_2,
    type: "Epice",
    phare: true,
    produits: [
      {
        id: 1,
        name: "CURRY SPECIAL VIANDE",
        conditionnement: [
          { unite: "vrac(1kg-5kg)" },
          { unite: "Doypack", grammage: "110g" },
          { unite: "verrine", grammage: "20g" },
          { unite: "SB", grammage: "100g" },
          { unite: "PET", grammage: "24g" },
          { unite: "Eprouvette", grammage: "38g" },
        ],
        description:
          "Le curry spécial viande est un mélange d'épices utilisé pour préparer des plats de viande savoureux et épicés. Il est généralement composé d'un mélange d'épices telles que le curcuma, le cumin, la coriandre, le gingembre, le poivre, la cannelle, la cardamome et le clou de girofle. ",
        imgUrl: imgCurry_3,
        gallery: [imgCurry_3, imgCurry_6, imgCurry_7, imgCurry_8],
        texture: "poudre",
        gout: "Complexe et épicé avec des notes chaudes, terreuses, douces et parfois piquantes",
        intensite: "Forte et distinctive",
        parfum: "",
        origine: "Madagascar",
        conservation:
          "récipient hermetique, à l'abri de la chaleur et de l'humidité.",
        composition: "anti-inflammatoires, antioxydantes",
      },
    ],
  },
  //EPICE POUR GUACAMOLE
  {
    id: 17,
    name: "EPICE POUR GUACAMOLE",
    description:
      "Les épices couramment utilisée dans le guacamole sont le cumin en poudre, le piment rouge en poudre, l'ail en poudre, la coriandre en poudre, la muscade en poudre, le sel fin blanc et le poudre de percil",
    imgUrl: imgGuacamole_1,
    imgUrl_1: imgGuacamole_2,
    type: "Epice",
    phare: true,
    produits: [
      {
        id: 1,
        name: "EPICE POUR GUACAMOLE",
        conditionnement: [
          { unite: "vrac(1kg-5kg)" },
          { unite: "Doypack", grammage: "100g" },
          { unite: "verrine", grammage: "22g" },
          { unite: "SB", grammage: "100g" },
          { unite: "PET", grammage: "15g" },
          { unite: "Eprouvette", grammage: "35g" },
        ],
        description:
          "Les épices couramment utilisée dans le guacamole sont le cumin en poudre, le piment rouge en poudre, l'ail en poudre, la coriandre en poudre, la muscade en poudre, le sel fin blanc et le poudre de percil",
        imgUrl: imgGuacamole_3,
        gallery: [imgGuacamole_3, imgGuacamole_6, imgGuacamole_9],
        texture: "poudre",
        gout: "Fumé ",
        intensite: "Modérée",
        parfum: "",
        origine: "Madagascar",
        conservation: "",
        composition: "",
      },
    ],
  },
  //FENUGREC
  {
    id: 18,
    name: "FENUGREC",
    description:
      "Le fenugrec est couramment utilisé dans les cuisines indienne, méditerranéenne et asiatique. Il est utilisé pour aromatiser les plats de viande, les bouillons et les marinades. Il est également utilisé dans la fabrication de fromages et de pains.",
    imgUrl: imgFenugrec_1,
    imgUrl_1: imgFenugrec_2,
    type: "Epice",
    phare: true,
    produits: [
      {
        id: 1,
        name: "GRAIN DE FENUGREC TORREFIE",
        conditionnement: [{ unite: "vrac(1kg-5kg)" }],
        description:
          "Le fenugrec est couramment utilisé dans les cuisines indienne, méditerranéenne et asiatique. Il est utilisé pour aromatiser les plats de viande, les bouillons et les marinades. Il est également utilisé dans la fabrication de fromages et de pains.",
        imgUrl: imgFenugrec_1,
        gallery: [imgFenugrec_1],
        texture: "grain",
        gout: "",
        intensite: "",
        parfum: "Distinctif similaire au sirop d'érable",
        origine: "Madagascar",
        conservation:
          "endroit frais, sec et à l'abri de la lumière directe du soleil",
        composition: "anti-inflammatoires, antioxydantes",
      },
      {
        id: 2,
        name: "FENUGREC EN POUDRE",
        conditionnement: [{ unite: "vrac(1kg-5kg)" }],
        description:
          "Le fenugrec est couramment utilisé dans les cuisines indienne, méditerranéenne et asiatique. Il est utilisé pour aromatiser les plats de viande, les bouillons et les marinades. Il est également utilisé dans la fabrication de fromages et de pains.",
        imgUrl: imgFenugrec_1,
        gallery: [imgFenugrec_1],
        texture: "poudre",
        gout: "",
        intensite: "",
        parfum: "Distinctif similaire au sirop d'érable",
        origine: "Madagascar",
        conservation:
          "endroit frais, sec et à l'abri de la lumière directe du soleil",
        composition: "anti-inflammatoires, antioxydantes",
      },
    ],
  },
  //FLEUR DE SEL
  {
    id: 19,
    name: "FLEUR DE SEL NATURE",
    description:
      "La fleur de sel est un sel marin délicat et croquant utilisé comme assaisonnement final pour rehausser les saveurs des plats. Ce qui en fait un produit de gamme apprécié par les chefs et les amateurs de cuisine. ",
    imgUrl: imgFds_1,
    imgUrl_1: imgFds_2,
    type: "Epice",
    phare: true,
    produits: [
      {
        id: 1,
        name: "FLEUR DE SEL NATURE",
        conditionnement: [
          { unite: "vrac(1kg-5kg)" },
          { unite: "Doypack", grammage: "180g" },
          { unite: "verrine", grammage: "40g" },
          { unite: "PET", grammage: "38g" },
          { unite: "Eprouvette", grammage: "70g" },
        ],
        description:
          "La fleur de sel est un sel marin délicat et croquant utilisé comme assaisonnement final pour rehausser les saveurs des plats. Ce qui en fait un produit de gamme apprécié par les chefs et les amateurs de cuisine. ",
        imgUrl: imgFds_1,
        gallery: [imgFds_2],
        texture: "cristaux blancs fins",
        gout: "délicat, subtil, avec des notes légèrement salées",
        intensite: "Légère",
        parfum: "",
        origine: "Madagascar",
        conservation:
          "récipient hermetique, à l'abri de la chaleur et de l'humidité.",
        composition: "",
      },
      {
        id: 2,
        name: "FLEUR DE SEL: BAIE ROSE - POIVRE NOIR CONCASSE",
        conditionnement: [
          { unite: "vrac(1kg-5kg)" },
          { unite: "Doypack", grammage: "160g" },
          { unite: "verrine", grammage: "40g" },
          { unite: "PET", grammage: "40g" },
          { unite: "Eprouvette", grammage: "65g" },
        ],
        description:
          "Fleur de sel de Madagascar aromatisée avec de la baie rose et du poivre noir concassés. 100% naturelle et sans additif.",
        imgUrl: imgFdsB_P_1,
        gallery: [imgFdsB_P_1, imgFdsB_P_2, imgFdsB_1, imgFdsB_2],
        texture: "cristaux blancs fins",
        gout: "délicat, subtil, avec des notes légèrement salées, Fruité avec de notes poivrées, Fort, piquant et légèrement chaud",
        intensite: "Légère",
        parfum: "agréable et délicat, Epicé ",
        origine: "Madagascar",
        conservation:
          "récipient hermetique, à l'abri de la chaleur et de l'humidité.",
        composition: "",
      },
      {
        id: 3,
        name: "FLEUR DE SEL: COMBAVA",
        conditionnement: [
          { unite: "vrac(1kg-5kg)" },
          { unite: "Doypack", grammage: "170g" },
          { unite: "verrine", grammage: "40g" },
          { unite: "PET", grammage: "38g" },
          { unite: "Eprouvette", grammage: "70g" },
        ],
        description:
          "Fleur de sel de Madagascar aromatisée aux poudres de zestes de combava.",
        imgUrl: imgFdsC_1,
        gallery: [imgFdsC_1, imgFdsC_2, imgFdsC_3],
        texture: "cristaux blancs fins",
        gout: "délicat, subtil, avec des notes légèrement salées,  Aromatique ",
        intensite: "Légère",
        parfum: "Citronné",
        origine: "Madagascar",
        conservation:
          "récipient hermetique, à l'abri de la chaleur et de l'humidité.",
        composition: "",
      },
      {
        id: 4,
        name: "FLEUR DE SEL: COMBAVA - GINGEMBRE",
        conditionnement: [
          { unite: "vrac(1kg-5kg)" },
          { unite: "Doypack", grammage: "170g" },
          { unite: "verrine", grammage: "40g" },
          { unite: "PET", grammage: "38g" },
          { unite: "Eprouvette", grammage: "70g" },
        ],
        description:
          "Fleur de sel de Madagascar aromatisée aux poudres de zestes de combava et gingembre. 100% naturelle et sans additif.",
        imgUrl: imgFdsC_G_1,
        gallery: [imgFdsC_G_1, imgFdsC_G_2, imgFdsG_1],
        texture: "cristaux blancs fins",
        gout: "délicat, subtil, avec des notes légèrement salées,  Aromatique, Piquant et épicé avec une légère note citronnée",
        intensite: "Légère",
        parfum: "Aromatique et distinctif, Citronné",
        origine: "Madagascar",
        conservation:
          "récipient hermetique, à l'abri de la chaleur et de l'humidité.",
        composition: "",
      },
      {
        id: 5,
        name: "FLEUR DE SEL: VANILLE",
        conditionnement: [
          { unite: "vrac(1kg-5kg)" },
          { unite: "Doypack", grammage: "170g" },
          { unite: "verrine", grammage: "40g" },
          { unite: "PET", grammage: "41g" },
          { unite: "Eprouvette", grammage: "70g" },
        ],
        description:
          "Fleur de sel de Madagascar aromatisée à la vanille bourbon de Madagascar.",
        imgUrl: imgFdsV_1,
        gallery: [imgFdsV_1, imgFdsV_2, imgFdsV_3],
        texture: "cristaux blancs fins",
        gout: "délicat, subtil, avec des notes légèrement salées,doux et légèrement floral ",
        intensite: "Légère",
        parfum: "Doux, sucré et légèrement floral",
        origine: "Madagascar",
        conservation:
          "récipient hermetique, à l'abri de la chaleur et de l'humidité.",
        composition: "",
      },
      {
        id: 6,
        name: "FLEUR DE SEL: VOATSIPERIFERY BLANC",
        conditionnement: [
          { unite: "vrac(1kg-5kg)" },
          { unite: "Doypack", grammage: "180g" },
          { unite: "verrine", grammage: "40g" },
          { unite: "PET", grammage: "40g" },
          { unite: "Eprouvette", grammage: "63g" },
        ],
        description:
          "Fleur de sel de Madagascar aromatisée au voatsiperifery blanc concassé.",
        imgUrl: imgFdsV_B_1,
        gallery: [imgFdsV_B_1, imgFdsV_B_2, imgFdsV_B_3],
        texture: "cristaux blancs fins",
        gout: "délicat, subtil, avec des notes légèrement salées, nique avec des notes d'agrumes, de bois et d'épices",
        intensite: "Légère",
        parfum: "intense et complexe",
        origine: "Madagascar",
        conservation:
          "récipient hermetique, à l'abri de la chaleur et de l'humidité.",
        composition: "",
      },
    ],
  },
  //GINGEMBRE
  {
    id: 20,
    name: "GINGEMBRE",
    description:
      "Le gingembre est largement utilisé dans les cuisines asiatiques, en particulier dans les plats indiens, chinois et thaïlandais. Il est utilisé pour aromatiser les plats de viande, les sautés, les currys, les marinades, les soupes, les sauces et les boissons comme le thé au gingembre.",
    imgUrl: imgGingembre_1,
    imgUrl_1: imgGingembre_2,
    type: "Epice",
    phare: true,
    produits: [
      {
        id: 1,
        name: "GINGEMBRE EN MORCEAU",
        conditionnement: [{ unite: "vrac(1kg-5kg)" }],
        description:
          "Le gingembre est largement utilisé dans les cuisines asiatiques, en particulier dans les plats indiens, chinois et thaïlandais. Il est utilisé pour aromatiser les plats de viande, les sautés, les currys, les marinades, les soupes, les sauces et les boissons comme le thé au gingembre.",
        imgUrl: imgGingembre_4,
        gallery: [
          imgGingembre_4,
          imgGingembre_6,
          imgGingembre_11,
          imgGingembre_10,
        ],
        texture: "morceau",
        gout: "Piquant et épicé avec une légère note citronnée",
        intensite: "Légère",
        parfum: "Aromatique et distinctif",
        origine: "Madagascar",
        conservation:
          "endroit frais, sec et à l'abri de la lumière directe du soleil",
        composition: "anti-inflammatoires, antioxydantes ",
      },
      {
        id: 2,
        name: "GINGEMBRE EN POUDRE",
        conditionnement: [
          { unite: "vrac(1kg-5kg)" },
          { unite: "Doypack", grammage: "110g" },
          { unite: "verrine", grammage: "22g" },
          { unite: "PET", grammage: "22g" },
          { unite: "SB", grammage: "100g" },
          { unite: "Eprouvette", grammage: "44g" },
        ],
        description:
          "Le gingembre est largement utilisé dans les cuisines asiatiques, en particulier dans les plats indiens, chinois et thaïlandais. Il est utilisé pour aromatiser les plats de viande, les sautés, les currys, les marinades, les soupes, les sauces et les boissons comme le thé au gingembre.",
        imgUrl: imgGingembre_4,
        gallery: [
          imgGingembre_4,
          imgGingembre_6,
          imgGingembre_11,
          imgGingembre_10,
        ],
        texture: "poudre",
        gout: "Piquant et épicé avec une légère note citronnée",
        intensite: "Légère",
        parfum: "Aromatique et distinctif",
        origine: "Madagascar",
        conservation:
          "endroit frais, sec et à l'abri de la lumière directe du soleil",
        composition: "anti-inflammatoires, antioxydantes ",
      },
    ],
  },
  //GIROFLE
  {
    id: 21,
    name: "GIROFLE",
    description:
      "Le clou de girofle est une épice polyvalente appréciée pour son goût épicé, son arôme intense et ses propriétés médicinales. Il est largement utilisé en cuisine, en médecine naturelle et pour ses effets répulsifs et aromatiques. ",
    imgUrl: imgGirofle_1,
    imgUrl_1: imgGirofle_2,
    type: "Epice",
    phare: true,
    produits: [
      {
        id: 1,
        name: "CLOU DE GIROFLE ",
        conditionnement: [
          { unite: "vrac(1kg-5kg)" },
          { unite: "Doypack", grammage: "120g" },
          { unite: "verrine", grammage: "20g" },
          { unite: "PET", grammage: "19g" },
          { unite: "SB", grammage: "100g" },
          { unite: "Eprouvette", grammage: "30g" },
        ],
        description:
          "Le clou de girofle est une épice polyvalente appréciée pour son goût épicé, son arôme intense et ses propriétés médicinales. Il est largement utilisé en cuisine, en médecine naturelle et pour ses effets répulsifs et aromatiques. ",
        imgUrl: imgGirofle_3,
        gallery: [
          imgGirofle_3,
          imgGirofle_5,
          imgGirofle_7,
          imgGirofle_8,
          imgGirofle_9,
          imgGirofle_10,
        ],
        texture: "clou",
        gout: "Chaud, piquant et légèrement sucré",
        intensite: "Intense",
        parfum: "Epicé et agréable",
        origine: "Madagascar",
        conservation:
          "endroit frais, sec et à l'abri de la lumière directe du soleil",
        composition:
          "antibactériennes, antifongiques, antivirales et analgésiques",
      },
      {
        id: 2,
        name: "GIROFLE EN POUDRE",
        conditionnement: [
          { unite: "vrac(1kg-5kg)" },
          { unite: "Doypack", grammage: "110g" },
          { unite: "verrine", grammage: "25g" },
          { unite: "PET", grammage: "26g" },
          { unite: "Eprouvette", grammage: "50g" },
        ],
        description:
          "Le clou de girofle est une épice polyvalente appréciée pour son goût épicé, son arôme intense et ses propriétés médicinales. Il est largement utilisé en cuisine, en médecine naturelle et pour ses effets répulsifs et aromatiques. ",
        imgUrl: imgGirofle_3,
        gallery: [imgGirofle_4, imgGirofle_11],
        texture: "poudre",
        gout: "Chaud, piquant et légèrement sucré",
        intensite: "Intense",
        parfum: "Epicé et agréable",
        origine: "Madagascar",
        conservation:
          "endroit frais, sec et à l'abri de la lumière directe du soleil",
        composition:
          "antibactériennes, antifongiques, antivirales et analgésiques",
      },
    ],
  },
  //GARAM MASSALA
  {
    id: 22,
    name: "GARAM MASSALA",
    description:
      "Le garam masala est utilisé comme assaisonnement dans de nombreux plats indiens, tels que les currys, les ragoûts, les sauces, les marinades et les plats de viande. Il peut également être ajouté aux plats de légumes, de riz, de soupes et de lentilles pour leur donner une saveur plus complexe.",
    imgUrl: imgMassala_1,
    imgUrl_1: imgMassala_2,
    type: "Epice",
    phare: true,
    produits: [
      {
        id: 1,
        name: "GARAM MASSALA",
        conditionnement: [
          { unite: "vrac(1kg-5kg)" },
          { unite: "Doypack", grammage: "130g" },
          { unite: "verrine", grammage: "20g" },
          { unite: "SB", grammage: "100g" },
          { unite: "PET", grammage: "20g" },
          { unite: "Eprouvette", grammage: "40g" },
        ],
        description:
          "Le garam masala est utilisé comme assaisonnement dans de nombreux plats indiens, tels que les currys, les ragoûts, les sauces, les marinades et les plats de viande. Il peut également être ajouté aux plats de légumes, de riz, de soupes et de lentilles pour leur donner une saveur plus complexe.",
        imgUrl: imgMassala_4,
        gallery: [imgMassala_4, imgMassala_11],
        texture: "poudre",
        gout: "Chaud, épicé et légèrement sucré, avec des notes de terre, de muscade et de clou de girofle",
        intensite: "Intense",
        parfum: "Epicé et agréable",
        origine: "Madagascar",
        conservation:
          "récipient hermetique, à l'abri de la chaleur et de l'humidité.",
        composition: "anti-inflammatoires, antioxydants",
      },
    ],
  },
  //MELANGE DE 3 BAIES
  {
    id: 23,
    name: "MELANGE DE 3 BAIES",
    description:
      "Le mélange de 3 baies est un mélange d'épices composé généralement de grains de poivre noir, de poivre blanc et de baies roses. L'ensemble offre un mélange équilibré et complexe de saveurs.",
    imgUrl: imgMelange3Baie_1,
    imgUrl_1: imgMelange3Baie_2,
    type: "Epice",
    phare: true,
    produits: [
      {
        id: 1,
        name: "MELANGE DE 3 BAIES",
        conditionnement: [
          { unite: "vrac(1kg-5kg)" },
          { unite: "Doypack", grammage: "210g" },
          { unite: "verrine", grammage: "22g" },
          { unite: "SB", grammage: "100g" },
          { unite: "PET", grammage: "26g" },
          { unite: "Eprouvette", grammage: "40g" },
        ],
        description:
          "Le mélange de 3 baies est un mélange d'épices composé généralement de grains de poivre noir, de poivre blanc et de baies roses. L'ensemble offre un mélange équilibré et complexe de saveurs.",
        imgUrl: imgMelange3Baie_3,
        gallery: [
          imgMelange3Baie_3,
          imgMelange3Baie_5,
          imgMelange3Baie_7,
          imgMelange3Baie_8,
          imgMelange3Baie_11,
          imgMelange3Baie_12,
          imgMelange3Baie_13,
        ],
        texture: "grain",
        gout: "Piquant, épicé et légèrement fruité",
        intensite: "Douce, Poivre noir avec des notes légèrement boisées",
        parfum: "Epicé",
        origine: "Madagascar",
        conservation:
          "endroit frais, sec et à l'abri de la lumière directe du soleil",
        composition: "anti-inflammatoires, antioxydants",
      },
    ],
  },
  //MELANGE DE 4 EPICES
  {
    id: 24,
    name: "MELANGE DE 4 EPICES",
    description:
      "Le mélange 4 épices est un mélange d'épices traditionnellement composé de poivre noir, de muscade, de clou de girofle et de cannelle. Le poivre noir ajoute une saveur piquante, la muscade apporte une note chaude et boisée, le clou de girofle ajoute une saveur intense et épicée, tandis que la cannelle apporte une saveur douce et aromatique. ",
    imgUrl: imgMelange4Epice_1,
    imgUrl_1: imgMelange4Epice_2,
    type: "Epice",
    phare: true,
    produits: [
      {
        id: 1,
        name: "MELANGE DE 4 EPICES",
        conditionnement: [
          { unite: "vrac(1kg-5kg)" },
          { unite: "Doypack", grammage: "210g" },
          { unite: "verrine", grammage: "25g" },
          { unite: "SB", grammage: "100g" },
          { unite: "PET", grammage: "20g" },
          { unite: "Eprouvette", grammage: "25g" },
        ],
        description:
          "Le mélange 4 épices est un mélange d'épices traditionnellement composé de poivre noir, de muscade, de clou de girofle et de cannelle. Le poivre noir ajoute une saveur piquante, la muscade apporte une note chaude et boisée, le clou de girofle ajoute une saveur intense et épicée, tandis que la cannelle apporte une saveur douce et aromatique. ",
        imgUrl: imgMelange4Epice_12,
        gallery: [imgMelange4Epice_12, imgMelange4Epice_6],
        texture: "poudre",
        gout: "Chaud, épicé et légèrement sucré",
        intensite: "Intense",
        parfum: "Epicé",
        origine: "Madagascar",
        conservation:
          "récipient hermetique, à l'abri de la chaleur et de l'humidité.",
        composition:
          "antioxydants, anti-inflammatoires, antifongiques naturels, fibres, calcium, fer, vitamine K, antibactériennes, antifongiques, antivirales et analgésiques",
      },
    ],
  },
  //MUSCADE
  {
    id: 25,
    name: "MUSCADE",
    description:
      "La muscade est utilisée dans de nombreux plats et boissons, notamment les sauces, les soupes, les ragoûts, les desserts, les boissons chaudes et les plats à base de fromage. Elle peut être utilisée en poudre ou selon les préférences et la recette.",
    imgUrl: imgMuscade_1,
    imgUrl_1: imgMuscade_2,
    type: "Epice",
    phare: true,
    produits: [
      {
        id: 1,
        name: "MUSCADE MACIS",
        conditionnement: [{ unite: "vrac(1kg-5kg)" }],
        description:
          "La muscade est utilisée dans de nombreux plats et boissons, notamment les sauces, les soupes, les ragoûts, les desserts, les boissons chaudes et les plats à base de fromage. Elle peut être utilisée en poudre ou selon les préférences et la recette.",
        imgUrl: imgMuscade_5,
        gallery: [imgMuscade_5],
        texture: "macis",
        gout: "Chaud, terrestre et légèrement sucré",
        intensite: "Intense avec des notes d'épices et de noix.",
        parfum: "Boisé et légèrement piquant",
        origine: "Madagascar",
        conservation:
          "endroit frais, sec et à l'abri de la lumière directe du soleil",
        composition:
          "antibacterien, anti-infammatoire,riche en vitamine du groupe B",
      },
      {
        id: 2,
        name: "MUSCADE EN POUDRE",
        conditionnement: [
          { unite: "vrac(1kg-5kg)" },
          { unite: "Doypack", grammage: "140g" },
          { unite: "verrine", grammage: "30g" },
          { unite: "Eprouvette", grammage: "42g" },
        ],
        description:
          "La muscade est utilisée dans de nombreux plats et boissons, notamment les sauces, les soupes, les ragoûts, les desserts, les boissons chaudes et les plats à base de fromage. Elle peut être utilisée en poudre ou selon les préférences et la recette.",
        imgUrl: imgMuscade_11,
        gallery: [imgMuscade_11],
        texture: "poudre",
        gout: "Chaud, terrestre et légèrement sucré",
        intensite: "Intense avec des notes d'épices et de noix.",
        parfum: "Boisé et légèrement piquant",
        origine: "Madagascar",
        conservation:
          "endroit frais, sec et à l'abri de la lumière directe du soleil",
        composition:
          "antibacterien, anti-infammatoire,riche en vitamine du groupe B",
      },
      {
        id: 3,
        name: "NOIX DE MUSCADE",
        conditionnement: [
          { unite: "vrac(1kg-5kg)" },
          { unite: "Doypack", grammage: "280g" },
          { unite: "verrine", grammage: "25g" },
          { unite: "SB", grammage: "50g" },
          { unite: "Eprouvette", grammage: "34g" },
        ],
        description:
          "La muscade est utilisée dans de nombreux plats et boissons, notamment les sauces, les soupes, les ragoûts, les desserts, les boissons chaudes et les plats à base de fromage. Elle peut être utilisée en poudre ou selon les préférences et la recette.",
        imgUrl: imgMuscade_10,
        gallery: [imgMuscade_10, imgMuscade_5, imgMuscade_9, imgMuscade_7],
        texture: "noix",
        gout: "Chaud, terrestre et légèrement sucré",
        intensite: "Intense avec des notes d'épices et de noix.",
        parfum: "Boisé et légèrement piquant",
        origine: "Madagascar",
        conservation:
          "endroit frais, sec et à l'abri de la lumière directe du soleil",
        composition:
          "antibacterien, anti-infammatoire,riche en vitamine du groupe B",
      },
    ],
  },
  //PIMENT ROUGE PILI PILI
  {
    id: 26,
    name: "PIMENT ROUGE PILI PILI",
    description:
      "Le piment rouge est utilisé dans de nombreuses cuisines du monde entier pour ajouter de la chaleur et du piquant aux plats. On le trouve souvent dans les sauces, les marinades, les plats épicés, les soupes, les viandes et les plats à base de légumes.",
    imgUrl: imgPimentRouge_1,
    imgUrl_1: imgPimentRouge_2,
    type: "Epice",
    phare: true,
    produits: [
      {
        id: 1,
        name: "PIMENT ROUGE PILI PILI EN ENTIER",
        conditionnement: [
          { unite: "vrac(1kg-5kg)" },
          { unite: "Doypack", grammage: "90g" },
          { unite: "verrine", grammage: "13g" },
          { unite: "SB", grammage: "50g" },
          { unite: "PET", grammage: "11g" },
          { unite: "Eprouvette", grammage: "22g" },
        ],
        description:
          "Le piment rouge est utilisé dans de nombreuses cuisines du monde entier pour ajouter de la chaleur et du piquant aux plats. On le trouve souvent dans les sauces, les marinades, les plats épicés, les soupes, les viandes et les plats à base de légumes.",
        imgUrl: imgPimentRouge_7,
        gallery: [imgPimentRouge_7],
        texture: "entiere",
        gout: "Vif et épicé",
        intensite: "Brûlante et piquante",
        parfum: "Très distinctif,  détectable même en petites quantités",
        origine: "Madagascar",
        conservation:
          "endroit frais, sec et à l'abri de la lumière directe du soleil",
        composition: "Fibre, vitamine C,antioxydants",
      },
      {
        id: 2,
        name: "PIMENT ROUGE PILI PILI EN POUDRE",
        conditionnement: [
          { unite: "vrac(1kg-5kg)" },
          { unite: "Doypack", grammage: "120g" },
          { unite: "verrine", grammage: "21g" },
          { unite: "SB", grammage: "100g" },
          { unite: "PET", grammage: "21g" },
          { unite: "Eprouvette", grammage: "39g" },
        ],
        description:
          "Le piment rouge est utilisé dans de nombreuses cuisines du monde entier pour ajouter de la chaleur et du piquant aux plats. On le trouve souvent dans les sauces, les marinades, les plats épicés, les soupes, les viandes et les plats à base de légumes.",
        imgUrl: imgPimentRouge_7,
        gallery: [imgPimentRouge_7],
        texture: "poudre",
        gout: "Vif et épicé",
        intensite: "Brûlante et piquante",
        parfum: "Très distinctif,  détectable même en petites quantités",
        origine: "Madagascar",
        conservation:
          "endroit frais, sec et à l'abri de la lumière directe du soleil",
        composition: "Fibre, vitamine C,antioxydants",
      },
    ],
  },
  //PIMENT VERT PILI PILI
  {
    id: 27,
    name: "PIMENT VERT PILI PILI",
    description:
      "Le piment vert est une variété de piment moins épicée et plus douce en saveur. Il offre une légère touche de chaleur avec une texture croquante. Il est utilisé dans de nombreuses préparations culinaires pour ajouter de la saveur et de la couleur. ",
    imgUrl: imgPimentVert_1,
    imgUrl_1: imgPimentVert_2,
    type: "Epice",
    phare: true,
    produits: [
      {
        id: 1,
        name: "PIMENT VERT PILI PILI EN ENTIER",
        conditionnement: [
          { unite: "vrac(1kg-5kg)" },
          { unite: "Doypack", grammage: "60g" },
          { unite: "verrine", grammage: "10g" },
          { unite: "SB", grammage: "40g" },
          { unite: "PET", grammage: "5g" },
          { unite: "Eprouvette", grammage: "17g" },
        ],
        description:
          "Le piment vert est une variété de piment moins épicée et plus douce en saveur. Il offre une légère touche de chaleur avec une texture croquante. Il est utilisé dans de nombreuses préparations culinaires pour ajouter de la saveur et de la couleur. ",
        imgUrl: imgPimentVert_3,
        gallery: [
          imgPimentVert_3,
          imgPimentVert_7,
          imgPimentVert_8,
          imgPimentVert_10,
        ],
        texture: "entiere",
        gout: "Chaleur légère sans brulure intense",
        intensite: "Doux et piquante",
        parfum: "Très distinctif,  détectable même en petites quantités",
        origine: "Madagascar",
        conservation:
          "endroit frais, sec et à l'abri de la lumière directe du soleil",
        composition: "vitamines A et C, antioxydants",
      },
      {
        id: 2,
        name: "PIMENT VERT PILI PILI EN POUDRE",
        conditionnement: [
          { unite: "vrac(1kg-5kg)" },
          { unite: "Doypack", grammage: "100g" },
          { unite: "verrine", grammage: "20g" },
          { unite: "SB", grammage: "100g" },
          { unite: "PET", grammage: "20g" },
          { unite: "Eprouvette", grammage: "30g" },
        ],
        description:
          "Le piment vert est une variété de piment moins épicée et plus douce en saveur. Il offre une légère touche de chaleur avec une texture croquante. Il est utilisé dans de nombreuses préparations culinaires pour ajouter de la saveur et de la couleur. ",
        imgUrl: imgPimentVert_5,
        gallery: [imgPimentVert_6, imgPimentVert_7, imgPimentVert_11],
        texture: "poudre",
        gout: "Chaleur légère sans brulure intense",
        intensite: "Doux et piquante",
        parfum: "Très distinctif,  détectable même en petites quantités",
        origine: "Madagascar",
        conservation:
          "endroit frais, sec et à l'abri de la lumière directe du soleil",
        composition: "vitamines A et C, antioxydants",
      },
    ],
  },
  //PIMENT TSILANDIMILAHY
  {
    id: 28,
    name: "PIMENT TSILANDIMILAHY",
    description:
      "Le piment Tsilanindimilahy est une variété de piment originaire de Madagascar. Ilest largement utilisé dans la cuisine malgache pour ajouter de la chaleur et du, les currys, les de viande.",
    imgUrl: imgTsilandimilahy_1,
    imgUrl_1: imgTsilandimilahy_2,
    type: "Epice",
    phare: true,
    produits: [
      {
        id: 1,
        name: "PIMENT TSILANDIMILAHY EN ENTIER",
        conditionnement: [{ unite: "vrac(1kg-5kg)" }],
        description:
          "Le piment Tsilanindimilahy est une variété de piment originaire de Madagascar. Ilest largement utilisé dans la cuisine malgache pour ajouter de la chaleur et du, les currys, les de viande.",
        imgUrl: imgTsilandimilahy_2,
        gallery: [imgTsilandimilahy_2],
        texture: "entiere",
        gout: "très épicé et piquant, niveau de chaleur élevé ",
        intensite: "Intense, piquante",
        parfum: "Très distinctif,  détectable même en petites quantités",
        origine: "Madagascar",
        conservation:
          "endroit frais, sec et à l'abri de la lumière directe du soleil",
        composition: "capsaïcine, anti-inflammatoires et antioxydantes",
      },
    ],
  },
  //POIVRE BLANC
  {
    id: 29,
    name: "POIVRE BLANC",
    description:
      "Le poivre blanc est souvent utilisé dans les plats que l'on souhaite éviter les taches noires, comme les sauces blanches, les soupes, les plats à base de poisson ou de volaille, ainsi que les plats à base de légumes.",
    imgUrl: imgPoivreBlanc_1,
    imgUrl_1: imgPoivreBlanc_2,
    type: "Epice",
    phare: true,
    produits: [
      {
        id: 1,
        name: "GRAIN DE POIVRE BLANC",
        conditionnement: [
          { unite: "vrac(1kg-5kg)" },
          { unite: "Doypack", grammage: "200g" },
          { unite: "verrine", grammage: "30g" },
          { unite: "SB", grammage: "100g" },
          { unite: "PET", grammage: "29g" },
          { unite: "Eprouvette", grammage: "50g" },
        ],
        description:
          "Le poivre blanc est souvent utilisé dans les plats que l'on souhaite éviter les taches noires, comme les sauces blanches, les soupes, les plats à base de poisson ou de volaille, ainsi que les plats à base de légumes.",
        imgUrl: imgPoivreBlanc_5,
        gallery: [imgPoivreBlanc_5, imgPoivreBlanc_7, imgPoivreBlanc_8],
        texture: "grain",
        gout: "Poivre noir avec des notes légèrement boisées",
        intensite: "Intense",
        parfum: "Epicé",
        origine: "Madagascar",
        conservation:
          "endroit frais, sec et à l'abri de la lumière directe du soleil",
        composition:
          "fibre, potassium, calcium, magnésium, phosphore, fer, manganèse, zinc, cuivre, sélénium et iode) et vitamines (E, A, B1, B2, B3, B6, b9).",
      },
      {
        id: 2,
        name: "POIVRE BLANC EN POUDRE",
        conditionnement: [
          { unite: "vrac(1kg-5kg)" },
          { unite: "Doypack", grammage: "120g" },
          { unite: "verrine", grammage: "25g" },
          { unite: "SB", grammage: "100g" },
          { unite: "PET", grammage: "25g" },
          { unite: "Eprouvette", grammage: "42g" },
        ],
        description:
          "Le poivre blanc est souvent utilisé dans les plats que l'on souhaite éviter les taches noires, comme les sauces blanches, les soupes, les plats à base de poisson ou de volaille, ainsi que les plats à base de légumes.",
        imgUrl: imgPoivreBlanc_4,
        gallery: [
          imgPoivreBlanc_4,
          imgPoivreBlanc_6,
          imgPoivreBlanc_11,
          imgPoivreBlanc_9,
        ],
        texture: "poudre",
        gout: "Poivre noir avec des notes légèrement boisées",
        intensite: "Intense",
        parfum: "Epicé",
        origine: "Madagascar",
        conservation:
          "endroit frais, sec et à l'abri de la lumière directe du soleil",
        composition:
          "fibre, potassium, calcium, magnésium, phosphore, fer, manganèse, zinc, cuivre, sélénium et iode) et vitamines (E, A, B1, B2, B3, B6, b9).",
      },
    ],
  },
  //POIVRE NOIR
  {
    id: 30,
    name: "POIVRE NOIR",
    description:
      "Le poivre noir est utilisé dans de nombreux plats et recettes du monde entier. Il ajoute de la saveur et de la chaleur aux sauces, aux viandes, aux légumes, aux soupes et aux marinades.",
    imgUrl: imgPoivreNoir_1,
    imgUrl_1: imgPoivreNoir_2,
    type: "Epice",
    phare: true,
    produits: [
      {
        id: 1,
        name: "GRAIN DE POIVRE NOIR ",
        conditionnement: [
          { unite: "vrac(1kg-5kg)" },
          { unite: "Doypack", grammage: "100g" },
          { unite: "verrine", grammage: "30g" },
          { unite: "SB", grammage: "100g" },
          { unite: "PET", grammage: "25g" },
          { unite: "Eprouvette", grammage: "48g" },
        ],
        description:
          "Le poivre noir est utilisé dans de nombreux plats et recettes du monde entier. Il ajoute de la saveur et de la chaleur aux sauces, aux viandes, aux légumes, aux soupes et aux marinades.",
        imgUrl: imgPoivreNoir_10,
        gallery: [
          imgPoivreNoir_10,
          imgPoivreNoir_4,
          imgPoivreNoir_12,
          imgPoivreNoir_13,
        ],
        texture: "grain",
        gout: "Fort, piquant et légèrement chaud",
        intensite: "Intense",
        parfum: "Epicé",
        origine: "Madagascar",
        conservation:
          "endroit frais, sec et à l'abri de la lumière directe du soleil",
        composition:
          "fibre, potassium, calcium, magnésium, phosphore, fer, manganèse, zinc, cuivre, sélénium et iode) et vitamines (E, A, B1, B2, B3, B6, b9).",
      },
      {
        id: 2,
        name: "POIVRE NOIR EN POUDRE",
        conditionnement: [
          { unite: "vrac(1kg-5kg)" },
          { unite: "Doypack", grammage: "120g" },
          { unite: "verrine", grammage: "25g" },
          { unite: "SB", grammage: "100g" },
          { unite: "PET", grammage: "25g" },
          { unite: "Eprouvette", grammage: "44g" },
        ],
        description:
          "Le poivre noir est utilisé dans de nombreux plats et recettes du monde entier. Il ajoute de la saveur et de la chaleur aux sauces, aux viandes, aux légumes, aux soupes et aux marinades.",
        imgUrl: imgPoivreNoir_14,
        gallery: [imgPoivreNoir_8, imgPoivreNoir_5, imgPoivreNoir_6],
        texture: "poudre",
        gout: "Fort, piquant et légèrement chaud",
        intensite: "Intense",
        parfum: "Epicé",
        origine: "Madagascar",
        conservation:
          "endroit frais, sec et à l'abri de la lumière directe du soleil",
        composition:
          "fibre, potassium, calcium, magnésium, phosphore, fer, manganèse, zinc, cuivre, sélénium et iode) et vitamines (E, A, B1, B2, B3, B6, b9).",
      },
      {
        id: 3,
        name: "POIVRE NOIR CONCASSE",
        conditionnement: [
          { unite: "vrac(1kg-5kg)" },
          { unite: "Doypack", grammage: "100g" },
          { unite: "verrine", grammage: "20g" },
          { unite: "SB", grammage: "100g" },
          { unite: "PET", grammage: "20g" },
          { unite: "Eprouvette", grammage: "37g" },
        ],
        description:
          "Le poivre noir est utilisé dans de nombreux plats et recettes du monde entier. Il ajoute de la saveur et de la chaleur aux sauces, aux viandes, aux légumes, aux soupes et aux marinades.",
        imgUrl: imgPoivreNoir_7,
        gallery: [imgPoivreNoir_7, imgPoivreNoir_9, imgPoivreNoir_3],
        texture: "concassee",
        gout: "Fort, piquant et légèrement chaud",
        intensite: "Intense",
        parfum: "Epicé",
        origine: "Madagascar",
        conservation:
          "endroit frais, sec et à l'abri de la lumière directe du soleil",
        composition:
          "fibre, potassium, calcium, magnésium, phosphore, fer, manganèse, zinc, cuivre, sélénium et iode) et vitamines (E, A, B1, B2, B3, B6, b9).",
      },
    ],
  },
  //VOATSIPERIFERY
  {
    id: 31,
    name: "VOATSIPERIFERY NOIR",
    description:
      "Le voatsiperifery noir de Madagascar est une variété spécifique de poivre noir qui provient de la région forestière de Madagascar. Le voatsiperifery noir est cultivé uniquement à Madagascar, dans les forêts tropicales de l'île. Il est récolté à la main et est considéré comme l'un des poivres les plus rares et les plus précieux au monde.",
    imgUrl: imgVoatsiperiferyNoir_1,
    imgUrl_1: imgVoatsiperiferyNoir_2,
    type: "Epice",
    phare: true,
    produits: [
      {
        id: 1,
        name: "VOATSIPERIFERY NOIR",
        conditionnement: [
          { unite: "vrac(1kg-5kg)" },
          { unite: "Doypack", grammage: "200g" },
          { unite: "verrine", grammage: "26g" },
          { unite: "SB", grammage: "100g" },
          { unite: "PET", grammage: "26g" },
          { unite: "Eprouvette", grammage: "40g" },
        ],
        description:
          "Le voatsiperifery noir de Madagascar est une variété spécifique de poivre noir qui provient de la région forestière de Madagascar. Le voatsiperifery noir est cultivé uniquement à Madagascar, dans les forêts tropicales de l'île. Il est récolté à la main et est considéré comme l'un des poivres les plus rares et les plus précieux au monde.",
        imgUrl: imgVoatsiperiferyNoir_3,
        gallery: [
          imgVoatsiperiferyNoir_3,
          imgVoatsiperiferyNoir_8,
          imgVoatsiperiferyNoir_5,
          imgVoatsiperiferyNoir_7,
        ],
        texture: "grain",
        gout: "Unique avec des notes d'agrumes, de bois et d'épices",
        intensite: "intense et complexe",
        parfum: "Epicé, champ de citronnier",
        origine: "Madagascar",
        conservation:
          "endroit frais, sec et à l'abri de la lumière directe du soleil",
        composition:
          "antibacterien, anti-infammatoire,minéraux, antioxydants, riche en vitamine (E,C,B)",
      },
      {
        id: 2,
        name: "VOATSIPERIFERY ROUGE",
        conditionnement: [
          { unite: "vrac(1kg-5kg)" },
          { unite: "Doypack", grammage: "200g" },
          { unite: "verrine", grammage: "26g" },
          { unite: "SB", grammage: "100g" },
          { unite: "PET", grammage: "70g" },
          { unite: "Eprouvette", grammage: "27g" },
        ],
        description:
          "Le voatsiperifery rouge est apprécié pour sa saveur unique et est utilisé principalement comme condiment. Il peut être utilisé pour assaisonner les viandes, les poissons, les légumes et les sauces.",
        imgUrl: imgVoatsiperiferyRouge_3,
        gallery: [
          imgVoatsiperiferyRouge_3,
          imgVoatsiperiferyRouge_5,
          imgVoatsiperiferyRouge_8,
          imgVoatsiperiferyRouge_7,
          imgVoatsiperiferyRouge_10,
          imgVoatsiperiferyRouge_9,
        ],
        texture: "grain",
        gout: "Modérément piquante, sans être trop forte, complexe, épicée et boisée, avec des notes légèrement sucrées, Il possède également une légère touche citronnée.",
        intensite: "Puissant et intense",
        parfum:
          " libérant des parfums aromatiques et exotiques rappelant la forêt tropicale",
        origine: "Madagascar",
        conservation:
          "endroit frais, sec et à l'abri de la lumière directe du soleil",
        composition:
          "antibacterien, anti-infammatoire,minéraux, antioxydants, riche en vitamine (E,C,B)",
      },
      {
        id: 3,
        name: "VOATSIPERIFERY BLANC",
        conditionnement: [
          { unite: "vrac(1kg-5kg)" },
          { unite: "Doypack", grammage: "250g" },
          { unite: "verrine", grammage: "30g" },
          { unite: "SB", grammage: "70g" },
          { unite: "PET", grammage: "30g" },
          { unite: "Eprouvette", grammage: "52g" },
        ],
        description:
          "Le voatsiperifery blanc est très apprécié par les chefs cuisiniers du monde entier en raison de sa saveur exquise. Il est considéré comme l'un des poivres les plus rares et les plus chers au monde en raison de sa rareté, de sa saveur unique et de son processus de récolte laborieux.",
        imgUrl: imgVoatsiperiferyBlanc_5,
        gallery: [
          imgVoatsiperiferyBlanc_5,
          imgVoatsiperiferyBlanc_8,
          imgVoatsiperiferyBlanc_7,
          imgVoatsiperiferyBlanc_12,
        ],
        texture: "grain",
        gout: " Unique et complexe ayant des notes chaudes et boisées, avec des nuances d'agrumes et de fleurs. ",
        intensite: "Légère chaleur qui se développe lentement sur la langue",
        parfum: " Intense et aromatique, avec des arômes floraux et fruités",
        origine: "Madagascar",
        conservation:
          "endroit frais, sec et à l'abri de la lumière directe du soleil",
        composition:
          "antibacterien, anti-infammatoire,minéraux,vitamines, , antioxydants",
      },
      {
        id: 4,
        name: "VOATSIPERIFERY NOIR EN POUDRE",
        conditionnement: [
          { unite: "vrac(1kg-5kg)" },
          { unite: "Doypack", grammage: "110g" },
          { unite: "verrine", grammage: "20g" },
          { unite: "SB", grammage: "70g" },
          { unite: "PET", grammage: "25g" },
          { unite: "Eprouvette", grammage: "47g" },
        ],
        description:
          "Le voatsiperifery noir de Madagascar est une variété spécifique de poivre noir qui provient de la région forestière de Madagascar. Le voatsiperifery noir est cultivé uniquement à Madagascar, dans les forêts tropicales de l'île. Il est récolté à la main et est considéré comme l'un des poivres les plus rares et les plus précieux au monde.",
        imgUrl: imgVoatsiperiferyNoir_9,
        gallery: [
          imgVoatsiperiferyNoir_9,
          imgVoatsiperiferyNoir_4,
          imgVoatsiperiferyNoir_6,
        ],
        texture: "poudre",
        gout: "Unique avec des notes d'agrumes, de bois et d'épices",
        intensite: "intense et complexe",
        parfum: "Epicé, champ de citronnier",
        origine: "Madagascar",
        conservation:
          "endroit frais, sec et à l'abri de la lumière directe du soleil",
        composition:
          "antibacterien, anti-infammatoire,minéraux,vitamines, , antioxydants",
      },
      {
        id: 5,
        name: "VOATSIPERIFERY BLANC EN POUDRE ",
        conditionnement: [
          { unite: "vrac(1kg-5kg)" },
          { unite: "Doypack", grammage: "250g" },
          { unite: "verrine", grammage: "30g" },
          { unite: "SB", grammage: "70g" },
          { unite: "PET", grammage: "30g" },
          { unite: "Eprouvette", grammage: "52g" },
        ],
        description:
          "Le voatsiperifery blanc est très apprécié par les chefs cuisiniers du monde entier en raison de sa saveur exquise. Il est considéré comme l'un des poivres les plus rares et les plus chers au monde en raison de sa rareté, de sa saveur unique et de son processus de récolte laborieux.",
        imgUrl: imgVoatsiperiferyBlanc_5,
        gallery: [
          imgVoatsiperiferyBlanc_5,
          imgVoatsiperiferyBlanc_8,
          imgVoatsiperiferyBlanc_7,
          imgVoatsiperiferyBlanc_12,
        ],
        texture: "poudre",
        gout: " Unique et complexe ayant des notes chaudes et boisées, avec des nuances d'agrumes et de fleurs. ",
        intensite: "Légère chaleur qui se développe lentement sur la langue",
        parfum: " Intense et aromatique, avec des arômes floraux et fruités",
        origine: "Madagascar",
        conservation:
          "endroit frais, sec et à l'abri de la lumière directe du soleil",
        composition:
          "antibacterien, anti-infammatoire,minéraux,vitamines, , antioxydants",
      },
    ],
  },
  //VANILLE
  {
    id: 32,
    name: "VANILLE",
    description:
      "La vanille est largement utilisée comme arôme dans les produits alimentaires. Elle est couramment utilisée dans les desserts tels que les crèmes glacées, les pâtisseries, les boissons chaudes et les sauces sucrées. Elle peut également être utilisée dans certains plats salés pour ajouter une touche d'arôme subtil.",
    imgUrl: imgVanille_1,
    imgUrl_1: imgVanille_2,
    type: "Epice",
    phare: true,
    produits: [
      {
        id: 1,
        name: "GOUSSE DE VANILLE",
        conditionnement: [
          { unite: "vrac(1kg-5kg)" },
          { unite: "Eprouvette", grammage: "35g" },
        ],
        description:
          "La vanille est largement utilisée comme arôme dans les produits alimentaires. Elle est couramment utilisée dans les desserts tels que les crèmes glacées, les pâtisseries, les boissons chaudes et les sauces sucrées. Elle peut également être utilisée dans certains plats salés pour ajouter une touche d'arôme subtil.",
        imgUrl: imgVanille_7,
        gallery: [imgVanille_7],
        texture: "gousse",
        gout: "Doux, sucré et légèrement floral",
        intensite: "intense et complexe",
        parfum: "trésor inépuisable de la parfumerie",
        origine: "Madagascar",
        conservation:
          "endroit frais, sec et à l'abri de la lumière directe du soleil",
        composition: "antioxydants, minéraux, oligo-éléments",
      },
      {
        id: 2,
        name: "VANILLE EN POUDRE",
        conditionnement: [
          { unite: "vrac(1kg-5kg)" },
          { unite: "verrine", grammage: "25g" },
          { unite: "Kraft" },
          { unite: "Eprouvette", grammage: "50g" },
        ],
        description:
          "La vanille est largement utilisée comme arôme dans les produits alimentaires. Elle est couramment utilisée dans les desserts tels que les crèmes glacées, les pâtisseries, les boissons chaudes et les sauces sucrées. Elle peut également être utilisée dans certains plats salés pour ajouter une touche d'arôme subtil.",
        imgUrl: imgVanille_7,
        gallery: [imgVanille_3, imgVanille_6, imgVanille_10],
        texture: "poudre",
        gout: "Doux, sucré et légèrement floral",
        intensite: "intense et complexe",
        parfum: "trésor inépuisable de la parfumerie",
        origine: "Madagascar",
        conservation:
          "endroit frais, sec et à l'abri de la lumière directe du soleil",
        composition: "antioxydants, minéraux, oligo-éléments",
      },
    ],
  },
  //MORINGA
  {
    id: 33,
    name: "MORINGA",
    description:
      "Les feuilles, les fleurs et les graines du moringa sont comestibles. Les feuilles peuvent être consommées crues, cuites ou sous forme de poudre. Les graines peuvent être utilisées pour extraire une huile riche en nutriments. Le moringa est également utilisé dans la préparation de thé, de soupes, de salades et de smoothies.",
    imgUrl: imgMoringa_1,
    imgUrl_1: imgMoringa_2,
    type: "Epice",
    phare: true,
    produits: [
      {
        id: 1,
        name: "MORINGA EN POUDRE",
        conditionnement: [{ unite: "vrac(1kg-5kg)" }],
        description:
          "Les feuilles, les fleurs et les graines du moringa sont comestibles. Les feuilles peuvent être consommées crues, cuites ou sous forme de poudre. Les graines peuvent être utilisées pour extraire une huile riche en nutriments. Le moringa est également utilisé dans la préparation de thé, de soupes, de salades et de smoothies.",
        imgUrl: imgMoringa_1,
        gallery: [imgMoringa_1],
        texture: "poudre",
        gout: "Doux, légèrement âpre et amer, évoquant les épinards, touche légèrement poivrée",
        intensite: "Légère",
        parfum: "aromatique, épicé",
        origine: "Madagascar",
        conservation:
          "endroit frais, sec et à l'abri de la lumière directe du soleil",
        composition:
          " vitamines (A, C, E), minéraux (calcium, fer, potassium), protéines.",
      },
    ],
  },
  //RIBAMBELLE D'EPICE
  {
    id: 34,
    name: "RIBAMBELLE D'EPICE",
    description: `Série de 10 épices en chapelet: Voatsiperifery en poudre, Cannelle en poudre, Poivre noir en poudre, Curcuma en poudre, Gingembre en poudre, Mélange 3 baies, Girofle en poudre, Voatsiperifery noir en grains, Cannelle en baton, Poivre noir en grains`,
    imgUrl: imgR_1,
    imgUrl_1: imgR_2,
    type: "Epice",
    phare: true,
    produits: [
      {
        id: 1,
        name: "RIBAMBELLE D'EPICE",
        conditionnement: [{ unite: "chapelet / 10g*10", grammage: "100g" }],
        description: `Série de 10 épices en chapelet: Voatsiperifery en poudre, Cannelle en poudre, Poivre noir en poudre, Curcuma en poudre, Gingembre en poudre, Mélange 3 baies, Girofle en poudre, Voatsiperifery noir en grains, Cannelle en baton, Poivre noir en grains`,
        imgUrl: imgR_1,
        gallery: [imgR_2],
        texture: "poudre",
        gout: "Série des 10 épices , chacun à son goût",
        intensite: "Intense, Légère",
        parfum: "Epicé",
        origine: "Madagascar",
        conservation:
          "endroit frais, sec et à l'abri de la lumière directe du soleil",
        composition: "",
      },
    ],
  },
  //L'EPICE POIVRE NOIR
  {
    id: 35,
    name: "L'EPICE POIVRE NOIR",
    description:
      "Serie des 5 épices en chapelet: Poivre noir en grain - Voasiperifery blanc en grain - Mélange 3 baies - Poivre blanc en grain - Voasiperifery noir en grain ",
    imgUrl: imgPoivreNoir_1,
    imgUrl_1: imgPoivreNoir_2,
    type: "Epice",
    phare: true,
    produits: [
      {
        id: 1,
        name: "L'EPICE POIVRE NOIR",
        conditionnement: [
          { unite: "Chapelet / 10g*3 et 5g*2", grammage: "40g" },
        ],
        description:
          "Serie des 5 épices en chapelet: Poivre noir en grain - Voasiperifery blanc en grain - Mélange 3 baies - Poivre blanc en grain - Voasiperifery noir en grain ",
        imgUrl: imgPoivreNoir_14,
        gallery: [imgPoivreNoir_8, imgPoivreNoir_5, imgPoivreNoir_6],
        texture: "grain",
        gout: "Série des 5 épices , chacun à son goût",
        intensite: "Intense, Légère",
        parfum: "Epicé",
        origine: "Madagascar",
        conservation:
          "endroit frais, sec et à l'abri de la lumière directe du soleil",
        composition: "",
      },
    ],
  },
  //L'EPICE FLEUR DE SEL
  {
    id: 36,
    name: "L'EPICE FLEUR DE SEL",
    description:
      "Serie des 5 fleurs de sel en chapelet: Combava en poudre - Baie rose concassé - Voatsiperifery blanc - Vanille en poudre - Combava et gingembre en poudre",
    imgUrl: imgFds_1,
    imgUrl_1: imgFds_2,
    type: "Epice",
    phare: true,
    produits: [
      {
        id: 1,
        name: "L'EPICE FLEUR DE SEL",
        conditionnement: [{ unite: "Chapelet / 20g*5", grammage: "100g" }],
        description:
          "Serie des 5 fleurs de sel en chapelet: Combava en poudre - Baie rose concassé - Voatsiperifery blanc - Vanille en poudre - Combava et gingembre en poudre",
        imgUrl: imgFds_1,
        gallery: [imgFds_2],
        texture: "sel",
        gout: "Série des 5 fleurs de sel marine, chacun à son goût, délicat, subtil, avec des notes légèrement salées",
        intensite: "Légère",
        parfum: "Epicé",
        origine: "Madagascar",
        conservation:
          "endroit frais, sec et à l'abri de la lumière directe du soleil",
        composition: "",
      },
    ],
  },
  //L'EPICE AIL POUDRE
  {
    id: 37,
    name: "L'EPICE AIL POUDRE",
    description:
      "Serie des 5 épices en chapelet: Ail poudre - Combava en poudre - Piment rouge en poudre - Poivre noir moulu - Sel nature",
    imgUrl: imgAil_1,
    imgUrl_1: imgAil_2,
    type: "Epice",
    phare: true,
    produits: [
      {
        id: 1,
        name: "L'EPICE AIL POUDRE",
        conditionnement: [
          { unite: "Chapelet / 10g*4 et 10 g * 1", grammage: "60g" },
        ],
        description:
          "Serie des 5 épices en chapelet: Ail poudre - Combava en poudre - Piment rouge en poudre - Poivre noir moulu - Sel nature",
        imgUrl: imgAil_4,
        gallery: [imgAil_4, imgAil_6],
        texture: "poudre",
        gout: "Série des 5 épices en poudre,chacun à son goût",
        intensite: "Légère",
        parfum: "Epicé",
        origine: "Madagascar",
        conservation:
          "endroit frais, sec et à l'abri de la lumière directe du soleil",
        composition: "",
      },
    ],
  },
  //L'EPICE COMBAVA POUDRE
  {
    id: 38,
    name: "L'EPICE COMBAVA POUDRE",
    description:
      "Serie des 5 épices en poudres en chapelet: Combava en poudre - Voatsiperifery noir moulu - Curcuma en poudre - Gingembre en poudre - Poivre noir moulu",
    imgUrl: imgCombova_1,
    imgUrl_1: imgCombova_2,
    type: "Epice",
    phare: true,
    produits: [
      {
        id: 1,
        name: "L'EPICE COMBAVA POUDRE",
        conditionnement: [{ unite: "Chapelet / 10g*5", grammage: "50g" }],
        description:
          "Serie des 5 épices en poudres en chapelet: Combava en poudre - Voatsiperifery noir moulu - Curcuma en poudre - Gingembre en poudre - Poivre noir moulu",
        imgUrl: imgCombova_11,
        gallery: [imgCombova_11, imgCombova_6, imgCombova_10],
        texture: "poudre",
        gout: "Série des 5 épices en poudre,chacun à son goût ",
        intensite: "Légère",
        parfum: "Epicé",
        origine: "Madagascar",
        conservation: "",
        composition: "",
      },
    ],
  },
  //ANANAS
  {
    id: 39,
    name: "ANANAS",
    description:
      "L'ananas séché est un fruit aux multiples vertus. Il est très riche en fibres et contient de la vitamine C. Il possède des vertus diététiques et peut servir de coupe faim ,À déguster tel quel comme une confiserie, ou bien s'en servir dans la confection d'une recette de dessert,L'ananas séché convient parfaitement pour la préparation des rhums arrangés",
    imgUrl: imgAnanas_1,
    imgUrl_1: imgAnanas_2,
    type: "Fruit",
    phare: true,
    produits: [
      {
        id: 1,
        name: "ANANAS",
        conditionnement: [
          { unite: "VRAC (1kg-5kg)", grammage: "50g" },
          { unite: "Doypack", grammage: "150g" },
        ],
        description:
          "L'ananas séché est un fruit aux multiples vertus. Il est très riche en fibres et contient de la vitamine C. Il possède des vertus diététiques et peut servir de coupe faim ,À déguster tel quel comme une confiserie, ou bien s'en servir dans la confection d'une recette de dessert,L'ananas séché convient parfaitement pour la préparation des rhums arrangés",
        imgUrl: imgAnanas_1,
        gallery: [],
        texture: "Morceau moelleuse",
        gout: "Sucré et acidulé",
        intensite: "douce",
        parfum: "odeur tirant vers le vinaigre",
        origine: "Madagascar",
        conservation:
          "récipient hermetique, à l'abri de la chaleur,endroit frais",
        composition: "vitamine C, vitamine B6, potassium, manganèse, fibre",
      },
    ],
  },
  //MANGUE
  {
    id: 40,
    name: "MANGUE",
    description:
      "Les Mangues séchées en lamelles ont une couleur jaune vive, traduisant la présence des caroténoïdes (provitamines A).Comme tout fruit sec, elles renferment 5 fois plus de minéraux que les fruits frais. Elles sont essentiellement riches en potassium et en magnésium. ",
    imgUrl: imgMangue_1,
    imgUrl_1: imgMangue_2,
    type: "Fruit",
    phare: true,
    produits: [
      {
        id: 1,
        name: "MANGUE",
        conditionnement: [
          { unite: "VRAC (1kg-5kg)", grammage: "" },
          { unite: "Doypack", grammage: "150g" },
        ],
        description:
          "Les Mangues séchées en lamelles ont une couleur jaune vive, traduisant la présence des caroténoïdes (provitamines A).Comme tout fruit sec, elles renferment 5 fois plus de minéraux que les fruits frais. Elles sont essentiellement riches en potassium et en magnésium. ",
        imgUrl: imgMangue_1,
        gallery: [],
        texture: "Lamelle, frite",
        gout: "Fruité",
        intensite: " Douce",
        parfum: "tropicaux",
        origine: "Madagascar",
        conservation:
          "récipient hermetique, à l'abri de la chaleur,endroit frais",
        composition:
          "fibres alimentaires, vitamines A et C, minéraux tels que le potassium et le magnésium, antioxydants",
      },
    ],
  },
  //LITCHI
  {
    id: 41,
    name: "LITCHI",
    description:
      "Le litchi de Madagascar figure parmi les plus recherchés au monde. Rafraîchissant et exotique ...consommés dans des salades de fruits ou cuits dans des pâtisseries, c'est un régal. Vous pouvez aussi l'incorporer à des préparations telles que de la confiture ou des sorbets.",
    imgUrl: imgLitchi_1,
    imgUrl_1: imgLitchi_2,
    type: "Fruit",
    phare: true,
    produits: [
      {
        id: 1,
        name: "LITCHI",
        conditionnement: [
          { unite: "VRAC (1kg-5kg)", grammage: "" },
          { unite: "Doypack", grammage: "150g" },
        ],
        description:
          "Le litchi de Madagascar figure parmi les plus recherchés au monde. Rafraîchissant et exotique ...consommés dans des salades de fruits ou cuits dans des pâtisseries, c'est un régal. Vous pouvez aussi l'incorporer à des préparations telles que de la confiture ou des sorbets.",
        imgUrl: imgLitchi_1,
        gallery: [],
        texture: "poudre",
        gout: "Sucré",
        intensite: "Intense",
        parfum: "Plus fort que le litchi frais",
        origine: "Madagascar",
        conservation:
          "récipient hermetique, à l'abri de la chaleur,endroit frais",
        composition: "fibres, vitamine C,antioxydant",
      },
    ],
  },
  //JACQUES
  {
    id: 42,
    name: "JACQUES",
    description:
      "Lorsque le fruit du jacquier est bien mûr, l'odeur de son écorce peut repousser, mais une fois retirée, le parfum et le goût sont semblables à ceux de la mangue, de la banane et de l'ananas.Vous pouvez consommer directement les fruits séchés ou les ajouter dans vos salades de fruits ou macérations d'alcools.",
    imgUrl: imgJacques_1,
    imgUrl_1: imgJacques_2,
    type: "Fruit",
    phare: true,
    produits: [
      {
        id: 1,
        name: "JACQUES",
        conditionnement: [
          { unite: "VRAC (1kg-5kg)", grammage: "" },
          { unite: "Doypack", grammage: "150g" },
        ],
        description:
          "Lorsque le fruit du jacquier est bien mûr, l'odeur de son écorce peut repousser, mais une fois retirée, le parfum et le goût sont semblables à ceux de la mangue, de la banane et de l'ananas.Vous pouvez consommer directement les fruits séchés ou les ajouter dans vos salades de fruits ou macérations d'alcools.",
        imgUrl: imgJacques_1,
        gallery: [],
        texture: "Morceau",
        gout: "Sucré",
        intensite: " Douce",
        parfum: "évoquent une odeur de fromage puant ou d'oignons rance",
        origine: "Madagascar",
        conservation: "",
        composition: "",
      },
    ],
  },
  //POK POK
  {
    id: 43,
    name: "POK POK",
    description:
      "Cette petite baie, protégée par son élégant calice nervé lorsque le fruit est arrivé à maturité, offre un goût acidulé et délicatement sucré.Ils peuvent se grignoter nature ou incorporés dans les préparation culinaires ou les salades de fruits. Ils conviennent aussi parfaitement pour la préparation des rhums arrangés et s'associent particulièrement avec la vanille et le cacao.",
    imgUrl: imgPokpok_1,
    imgUrl_1: imgPokpok_2,
    type: "Fruit",
    phare: true,
    produits: [
      {
        id: 1,
        name: "POK POK",
        conditionnement: [
          { unite: "VRAC (1kg-5kg)", grammage: "" },
          { unite: "Doypack", grammage: "200g" },
        ],
        description:
          "Cette petite baie, protégée par son élégant calice nervé lorsque le fruit est arrivé à maturité, offre un goût acidulé et délicatement sucré.Ils peuvent se grignoter nature ou incorporés dans les préparation culinaires ou les salades de fruits. Ils conviennent aussi parfaitement pour la préparation des rhums arrangés et s'associent particulièrement avec la vanille et le cacao.",
        imgUrl: imgPokpok_1,
        gallery: [],
        texture: "grain",
        gout: "Sucré et acidulé",
        intensite: "Leger",
        parfum: "Exotique",
        origine: "Madagascar",
        conservation:
          "récipient hermetique, à l'abri de la chaleur,endroit frais",
        composition: "vitamines, minéraux, antioxydants",
      },
    ],
  },
  //COCO
  {
    id: 44,
    name: "COCO",
    description:
      "Goût exotique : Les lamelles de coco séchées ont un goût sucré et exotique de noix de coco, ce qui en fait une collation délicieuse et satisfaisante pour les amateurs de coco.Souvent incorporée dans des cakes ou des gâteaux, mais on peut également l'utiliser dans des barres énergétiques, dans du thé glacé ou dans des salades.",
    imgUrl: imgCoco_1,
    imgUrl_1: imgCoco_2,
    type: "Fruit",
    phare: true,
    produits: [
      {
        id: 1,
        name: "COCO",
        conditionnement: [
          { unite: "VRAC (1kg-5kg)", grammage: "" },
          { unite: "Doypack", grammage: "150g" },
        ],
        description:
          "Goût exotique : Les lamelles de coco séchées ont un goût sucré et exotique de noix de coco, ce qui en fait une collation délicieuse et satisfaisante pour les amateurs de coco.Souvent incorporée dans des cakes ou des gâteaux, mais on peut également l'utiliser dans des barres énergétiques, dans du thé glacé ou dans des salades.",
        imgUrl: imgCoco_1,
        gallery: [],
        texture: "Morceau",
        gout: "Exotique",
        intensite: "craquant",
        parfum: "Exotique",
        origine: "Madagascar",
        conservation:
          "récipient hermetique, à l'abri de la chaleur,endroit frais",
        composition: " fibres, minéraux, fer, acides gras,",
      },
    ],
  },
];
